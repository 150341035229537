import React, { useState, useContext, useEffect } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { Row, Input, FormFeedback } from "reactstrap";

import { observer } from "mobx-react";
import "./style.scss";

import { IntlContext } from "../../../../utility/context/IntelContext";
// import DatePickerHijri from "@deskpro/react-datepicker-hijri";

import TextInputCommon from "../../../components/common/input/TextInput";
import { lineListInitialValues } from "../../../../utility/constants/LineList";
import {
  canCreateLineList,
  canUpdateLineList,
  checkFormStatus,
} from "../OutbreakInformation/helpers";
import { useParams } from "react-router-dom";

// import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";

export const ValidationHandlerMain = observer(
  ({ isValid, message = `"This field is required!"`, modal = {} }) => {
    return (
      <>
        <Input type="text" hidden invalid={!isValid && modal.isValidation} />
        <FormFeedback>
          <FormattedMessage id={message} defaultMessage={message} />
        </FormFeedback>
      </>
    );
  }
);

const ListLine = observer(({ item, siteType, formik, editData }) => {
  const [lineListItems, setLineListItems] = useState(lineListInitialValues);
  const intl = useIntl();
  const intlContext = useContext(IntlContext);
  const [inputModal, setInputModal] = useState(false);
  const isEn = intlContext.locale === "en";
  const { id } = useParams();

  useEffect(() => {
    // getRegions();
  }, []);

  const isDisabled = () => {
    const formStatus = formik.values?.closure?.event_status?.value
    return id ? (checkFormStatus(formStatus) || !canUpdateLineList) : !canCreateLineList;
  }

  let lineList = formik.values?.lineList;

  const {
    total_cases_value,
    total_hcw_cases_value,
    non_hcw_suspect_cases,
    non_hcw_probable_cases,
    non_hcw_confirmed_cases,
    total_non_hcw_cases_value,
    total_death_value,
    total_hwc_death_value,
    non_hwc_death_suspect_cases,
    non_hwc_death_probable_cases,
    non_hwc_death_confirmed_cases,
    total_non_hcw_death_cases_value,
    total_cirtical_cases_value,
    total_cirtical_icu_cases_value,
  } = lineListExtract(lineList);

  return (
    <Row className="Main-class line-list">
      <table className="table table-borderless">
        <thead className="main-head">
          <tr className="">
            <th className="th-res-1"></th>
            <th className="th-res">Suspect</th>
            <th className="th-res">Probable</th>
            <th className="th-res">Confirmed</th>
            <th className="th-res-2">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Cases</td>
            <td>
              <TextInputCommon
                value={lineList?.total_suspect_cases}
                name="lineList.total_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.total_probable_cases}
                name="lineList.total_probable_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.total_confirmed_cases}
                name="lineList.total_confirmed_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={total_cases_value}
                name="lineList.total_cases_value"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
          </tr>

          <tr>
            <td>HCW cases</td>
            <td>
              <TextInputCommon
                value={lineList?.hcw_suspect_cases}
                name="lineList.hcw_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.hcw_probable_cases}
                name="lineList.hcw_probable_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.hcw_confirmed_cases}
                name="lineList.hcw_confirmed_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={total_hcw_cases_value}
                name="lineList.hcw_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
          </tr>

          <tr>
            <td>Non HCW cases</td>
            <td>
              <TextInputCommon
                value={non_hcw_suspect_cases}
                name="lineList.non_hcw_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
            <td>
              <TextInputCommon
                value={non_hcw_probable_cases}
                name="lineList.non_hcw_probable_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
            <td>
              <TextInputCommon
                value={non_hcw_confirmed_cases}
                name="lineList.non_hcw_confirmed_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>

            <td>
              <TextInputCommon
                value={total_non_hcw_cases_value}
                name="lineList.non_hcw_cases"
                placeholder=""
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
          </tr>

          <tr>
            <td>Total deaths</td>
            <td>
              <TextInputCommon
                value={lineList?.death_suspect_cases}
                name="lineList.death_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.death_probable_cases}
                name="lineList.death_probable_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.death_confirmed_cases}
                name="lineList.death_confirmed_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={total_death_value}
                name="lineList.death_cases"
                placeholder=""
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
          </tr>

          <tr>
            <td>HCW deaths</td>
            <td>
              <TextInputCommon
                value={lineList?.hwc_death_suspect_cases}
                name="lineList.hwc_death_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.hwc_death_probable_cases}
                name="lineList.hwc_death_probable_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.hwc_death_confirmed_cases}
                name="lineList.hwc_death_confirmed_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={total_hwc_death_value}
                name="lineList.hwc_death_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
          </tr>

          <tr>
            <td>Non HCW deaths</td>
            <td>
              <TextInputCommon
                value={non_hwc_death_suspect_cases}
                name="lineList.non_hwc_death_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
            <td>
              <TextInputCommon
                value={non_hwc_death_probable_cases}
                name="lineList.non_hwc_death_probable_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
            <td>
              <TextInputCommon
                value={non_hwc_death_confirmed_cases}
                name="lineList.non_hwc_death_confirmed_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
            <td>
              <TextInputCommon
                value={total_non_hcw_death_cases_value}
                name="lineList.non_hwc_death_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
          </tr>

          <tr>
            <td>Hospitalized</td>
            <td>
              <TextInputCommon
                value={lineList?.critical_suspect_cases}
                name="lineList.critical_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.critical_probable_cases}
                name="lineList.critical_probable_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.critical_confirmed_cases}
                name="lineList.critical_confirmed_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={total_cirtical_cases_value}
                name="lineList.critical_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
          </tr>

          <tr>
            <td>Critical (ICU)</td>
            <td>
              <TextInputCommon
                value={lineList?.critical_icu_suspect_cases}
                name="lineList.critical_icu_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.critical_icu_probable_cases}
                name="lineList.critical_icu_probable_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={lineList?.critical_icu_confirmed_cases}
                name="lineList.critical_icu_confirmed_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                disabled={isDisabled()}
              />
            </td>
            <td>
              <TextInputCommon
                value={total_cirtical_icu_cases_value}
                name="lineList.critical_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="number"
                min='0'
                isDisable={true}
              />
            </td>
          </tr>

          <tr>
            <td>Cases Narrative</td>
            <td colSpan={4}>
              <TextInputCommon
                value={lineList?.narrative_suspect_cases}
                name="lineList.narrative_suspect_cases"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="textarea"
                disabled={isDisabled()}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Row>
  );
});

export default ListLine;

export function lineListExtract(lineList) {
  const total_cases_value =
    (lineList?.total_suspect_cases || 0) +
    (lineList?.total_probable_cases || 0) +
    (lineList?.total_confirmed_cases || 0);
  const total_hcw_cases_value =
    (lineList?.hcw_suspect_cases || 0) +
    (lineList?.hcw_probable_cases || 0) +
    (lineList?.hcw_confirmed_cases || 0);
  const total_non_hcw_cases_value =
    total_cases_value - total_hcw_cases_value >= 0
      ? total_cases_value - total_hcw_cases_value
      : total_cases_value;
  const total_death_value =
    (lineList?.death_suspect_cases || 0) +
    (lineList?.death_probable_cases || 0) +
    (lineList?.death_confirmed_cases || 0);
  const total_hwc_death_value =
    (lineList?.hwc_death_suspect_cases || 0) +
    (lineList?.hwc_death_probable_cases || 0) +
    (lineList?.hwc_death_confirmed_cases || 0);

  const total_non_hcw_death_cases_value =
    total_death_value - total_hwc_death_value > 0
      ? total_death_value - total_hwc_death_value
      : total_death_value;

  const total_cirtical_cases_value =
    (lineList?.critical_suspect_cases || 0) +
    (lineList?.critical_probable_cases || 0) +
    (lineList?.critical_confirmed_cases || 0);
  const total_cirtical_icu_cases_value =
    (lineList?.critical_icu_suspect_cases || 0) +
    (lineList?.critical_icu_probable_cases || 0) +
    (lineList?.critical_icu_confirmed_cases || 0);
  const non_hcw_suspect_cases =
    lineList?.total_suspect_cases - lineList?.hcw_suspect_cases >= 0
      ? lineList?.total_suspect_cases - lineList?.hcw_suspect_cases
      : lineList?.total_suspect_cases;

  const non_hcw_probable_cases =
    lineList?.total_probable_cases - lineList?.hcw_probable_cases >= 0
      ? lineList?.total_probable_cases - lineList?.hcw_probable_cases
      : lineList?.total_probable_cases;

  const non_hcw_confirmed_cases =
    lineList?.total_confirmed_cases - lineList?.hcw_confirmed_cases >= 0
      ? lineList?.total_confirmed_cases - lineList?.hcw_confirmed_cases
      : lineList?.total_confirmed_cases;
  const non_hwc_death_suspect_cases =
    lineList?.death_suspect_cases - lineList?.hwc_death_suspect_cases >= 0
      ? lineList?.death_suspect_cases - lineList?.hwc_death_suspect_cases
      : lineList?.death_suspect_cases;

  const non_hwc_death_probable_cases =
    lineList?.death_probable_cases - lineList?.hwc_death_probable_cases >= 0
      ? lineList?.death_probable_cases - lineList?.hwc_death_probable_cases
      : lineList?.death_probable_cases;

  const non_hwc_death_confirmed_cases =
    lineList?.death_confirmed_cases - lineList?.hwc_death_confirmed_cases >= 0
      ? lineList?.death_confirmed_cases - lineList?.hwc_death_confirmed_cases
      : lineList?.death_confirmed_cases;
  return {
    ...lineList,
    total_cases_value,
    total_hcw_cases_value,
    non_hcw_suspect_cases,
    non_hcw_probable_cases,
    non_hcw_confirmed_cases,
    total_non_hcw_cases_value,
    total_death_value,
    total_hwc_death_value,
    non_hwc_death_suspect_cases,
    non_hwc_death_probable_cases,
    non_hwc_death_confirmed_cases,
    total_non_hcw_death_cases_value,
    total_cirtical_cases_value,
    total_cirtical_icu_cases_value,
  };
}
