import React, { useState } from "react";

const Hello = ({ currValue, setCurrValue, isDisable = false }) => {
  const handlePlatformChange = (event) => {
    const val = event.currentTarget.querySelector("input").value;
    setCurrValue(val);
  };

  return (
    <div>
      {/* <div>{currValue}</div> */}
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        <label
          className={`btn text-nowrap ${
            currValue === "APPLICABLE" ? "accept-btn" : "accept-btn-defaut"
          }`}
          onClick={(e) => {
            if (!isDisable) handlePlatformChange(e);
          }}
        >
          <input
            className="d-none"
            type="radio"
            name="platform"
            value="APPLICABLE"
            autoComplete="off"
          />{" "}
          Applicable
        </label>
        <label
          className={`btn text-nowrap ${
            currValue === "DEFERRED" ? "defferd-btn" : "defferd-btn-defaut"
          }`}
          onClick={(e) => {
            if (!isDisable) handlePlatformChange(e);
          }}
        >
          <input
            className="d-none"
            type="radio"
            name="platform"
            value="DEFERRED"
            autoComplete="off"
          />{" "}
          Deferred
        </label>
        <label
          className={`btn text-nowrap ${
            currValue === "NOT_APPLICABLE"
              ? "not-accpeted-btn"
              : "not-accpeted-btn-defaut"
          }`}
          onClick={(e) => {
            if (!isDisable) handlePlatformChange(e);
          }}
        >
          <input
            className="d-none"
            type="radio"
            name="platform"
            value="NOT_APPLICABLE"
            autoComplete="off"
          />{" "}
          Not applicable
        </label>
      </div>
    </div>
  );
};

export default Hello;
