import React, { useState } from "react";

const MultiSites = ({ name, value, handleChange, isDisabled = false }) => {
  const handlePlatformChange = (event) => {
    const val = event.currentTarget.querySelector("input").value;
    handleChange(name, val);
  };

  return (
    <div>
      {/* <div>{currValue}</div> */}
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        <label
          className={`btn ${
            value === "applicable" ? "accept-btn" : "accept-btn-defaut"
          }`}
          onClick={(e) => {
            if (!isDisabled) handlePlatformChange(e);
          }}
          disabled={isDisabled}
        >
          <input
            className="d-none"
            type="radio"
            name="platform"
            value="applicable"
            autoComplete="off"
            disabled={isDisabled}
          />{" "}
          Yes
        </label>
        <label
          className={`btn ${
            value === "not-applicable"
              ? "not-accpeted-btn"
              : "not-accpeted-btn-defaut"
          }`}
          onClick={(e) => {
            if (!isDisabled) handlePlatformChange(e);
          }}
          disabled={isDisabled}
        >
          <input
            className="d-none"
            type="radio"
            name="platform"
            value="not-applicable"
            autoComplete="off"
            disabled={isDisabled}
          />{" "}
          No
        </label>
      </div>
    </div>
  );
};

export default MultiSites;
