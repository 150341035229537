import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { selectThemeColors } from "@utils";
import { RegionSiteModal } from "../../../wasfaty/Models/RegionSiteModal";
import { IntlService, RegionService } from "../../../wasfaty/services";

import DatePickerComponent from "../../../components/common/datePicker/DatePicker";
import SelectComponent from "../../../components/common/select/Select";
import TextInputCommon from "../../../components/common/input/TextInput";

import "./style.scss";
import { SC } from "../../../ESurvey/Helper/helper";
import {
  canCreateNotification,
  canUpdateNotification,
  checkFormStatus,
  event_existance_options,
  getDateTimeFormat,
  isBottleneckTextAreaEnabled,
} from "../OutbreakInformation/helpers";
import { useParams } from "react-router-dom";
import moment from "moment";
import { AlertCircle } from "react-feather";

const Notification = observer(({ formik }) => {
  const [dropDrowns, setDropDrowns] = useState(undefined);
  const [notification_source_options, setnotification_source_options] =
    useState(undefined);

  const intl = useIntl();
  const { id } = useParams();
  const getTranslation = (text) => {
    return intl.formatMessage({
      id: text,
      defaultMessage: text,
    });
  };

  const getDropDowns = () => {
    SC.getCall("outbreak/formOptions?step=NOTIFICATION").then((res) => {
      if (res?.data) setDropDrowns(res.data);
    });
    SC.getCall("notification/all").then((res) => {
      let op = res.data.data.map((it) => {
        return { ...it, label: it.name, value: it._id };
      });
      setnotification_source_options(op);
    });
  };

  const isDisabled = () => {
    const formStatus = formik.values?.closure?.event_status?.value
    return id ? (checkFormStatus(formStatus) || !canUpdateNotification) : !canCreateNotification;
  }

  useEffect(() => {
    getDropDowns();
  }, []);

  return (
    <Row>
      <Card>
        <CardBody>
          <Row>
            <Col className="mb-1" md="12" sm="12">
            <UncontrolledTooltip placement="top" target="date_and_time" innerClassName="custom-tooltip">
                                        •  Date the event is first reported to the relevant public health authority responsible for action. 
                                      </UncontrolledTooltip>
                          
                                      {/* Label container */}
                                      <div 
                                        id="date_and_time" 
                                        className="d-inline-flex align-items-center"
                                        style={{ paddingInline: '5px', width: 'fit-content' }}
                                      >
                                        <AlertCircle size={18} className="cursor-pointer" style={{marginTop: -5}} />
                                        <label htmlFor="datePicker" className="form-label" style={{marginLeft: 5}}>Date & Time of Notification*</label>
                                        </div>
              <DatePickerComponent
                // label="Date & Time of Notification*"
                placeholder="Date & Time of Notification"
                name="notification.date_of_notification"
                value={formik.values?.notification?.date_of_notification}
                formik={formik}
                handleChange={([date]) => {
                  formik.setFieldValue(
                    "notification.date_of_notification",
                    getDateTimeFormat(date)
                  );
                }}
                onBlur={formik.handleBlur}
                options={{
                  enableTime: true,
                  time_24hr: true,
                  dateFormat: "Y-m-d H:i",
                }}
                isDisable={isDisabled() }
              />
            </Col>
            <Col className="mb-1" md="6" sm="12">
              <SelectComponent
                label="Notification Source*"
                name="notification.notification_source"
                value={formik.values?.notification?.notification_source}
                formik={formik}
                onChange={(value) => {
                  formik.setFieldValue(
                    "notification.notification_source",
                    value
                  );
                }}
                onBlur={(e) => {
                  formik.setFieldTouched(
                    "notification.notification_source",
                    true
                  );
                }}
                options={notification_source_options}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="6" sm="12">
              <SelectComponent
                label="Event Occurrence at Notification?*"
                name="notification.event_existance"
                value={formik.values?.notification?.event_existance}
                formik={formik}
                onChange={(value) => {
                  formik.setFieldValue("notification.event_existance", value);
                  if (value?.value === event_existance_options[0]?.value) {
                    formik.setFieldValue(
                      "notification.event_existence_established",
                      { label: "Not verified", value: "Not verified" }
                    );
                  } else if (
                    value?.value === event_existance_options[1]?.value
                  ) {
                    formik.setFieldValue(
                      "notification.event_existence_established",
                      { label: "Verified", value: "Verified" }
                    );
                    formik.setFieldValue(
                      "notification.event_existence_established_check",
                      false
                    );
                  }
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("notification.event_existance", true);
                }}
                options={event_existance_options}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="12" sm="12">
              <TextInputCommon
                value={formik.values?.notification?.notification_narrative}
                name="notification.notification_narrative"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Notification Narrative"
                placeholder="Notification Narrative"
                formik={formik}
                type="textarea"
                rows="5"
                disabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="12" sm="12">
              <SelectComponent
                label="Notification Bottlenecks*"
                name="notification.notification_bottlenecks"
                value={formik.values?.notification?.notification_bottlenecks}
                formik={formik}
                onChange={(value) => {
                  const hasNoBottlenecksOption =
                    formik.values?.notification?.notification_bottlenecks?.some(
                      (option) => option.value === "NO_BOTTLENECKS"
                    );
                  const hasNoBottlenecksOptionInValue = value?.some(
                    (option) => option.value === "NO_BOTTLENECKS"
                  );
                  if (
                    value.length > 1 &&
                    value[value.length - 1].value === "NO_BOTTLENECKS"
                  ) {
                    console.log("can not select bottleneck");
                  } else if (
                    (!hasNoBottlenecksOption ||
                      !hasNoBottlenecksOptionInValue) &&
                    value.length <= 3
                  )
                    formik.setFieldValue(
                      "notification.notification_bottlenecks",
                      value
                    );
                }}
                onBlur={(e) => {
                  formik.setFieldTouched(
                    "notification.notification_bottlenecks",
                    true
                  );
                }}
                options={dropDrowns?.NOTIFICATION_BOTTLENECKS || []}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isMulti
                isDisabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="12" sm="12">
              <TextInputCommon
                value={formik.values?.notification?.notification_enablers}
                name="notification.notification_enablers"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Notification Enablers"
                placeholder="Notification Enablers"
                formik={formik}
                type="textarea"
                rows="5"
                disabled={isDisabled()}
              />
            </Col>
            {/* <Col className="mb-1" md="12" sm="12">
              <SelectComponent
                label="Event Verification after Response?*"
                name="notification.event_existence_established"
                value={formik.values?.notification?.event_existence_established}
                formik={formik}
                onChange={(value) => {
                  formik.setFieldValue(
                    "notification.event_existence_established",
                    value
                  );
                }}
                onBlur={(e) => {
                  formik.setFieldTouched(
                    "notification.event_existence_established",
                    true
                  );
                }}
                options={["Not confirmed", "Confirmed"].map((item) => {
                  return { label: item, value: item };
                })}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={
                  isDisabled()
                }
              />
            </Col> */}
           
            {isBottleneckTextAreaEnabled(
              formik.values?.notification?.notification_bottlenecks
            ) && (
              <Col className="mb-1" md="12" sm="12">
                <TextInputCommon
                  value={
                    formik.values?.notification?.notification_bottlenecks_text
                  }
                  name="notification.notification_bottlenecks_text"
                  label="Other Specify*"
                  placeholder="Other Specify"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  disabled={isDisabled()}
                />
              </Col>
            )}
           
            {formik.values?.notification.notification_source?.value ===
              "OTHER" && (
              <Col className="mb-1" md="6" sm="12">
                <TextInputCommon
                  value={
                    formik.values?.notification?.notification_source_free_text
                  }
                  name="notification.notification_source_free_text"
                  label="Other*"
                  placeholder="Other"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  disabled={isDisabled()}
                />
              </Col>
            )}
          
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
});

export default Notification;
