import React from "react";
import { FormattedMessage } from "react-intl";
import Select from "react-select";

import { Label } from "reactstrap";
import { FormikError } from "../../../pages/Add/OutbreakInformation/helpers";
import { observer } from "mobx-react";
import Check from "../check/Check";

const CustomOptionComponent = ({ innerProps, innerRef, children }) => (
  <div ref={innerRef} {...innerProps}>
    {children}
  </div>
);
const SelectComponent = observer(
  ({ label, options, selectedValue, placeholder, ...rest }) => {
    console.log("🚀 ~ rest:", rest)
    return (
      <div className="position-relative mb-1">
        {label && (
          <Label className="form-label ">
            <FormattedMessage id={label} defaultMessage={label} />
          </Label>
        )}
        <div className="select">
          <Select
            options={options}
            placeholder={placeholder}
            closeMenuOnSelect={!rest.isMulti}
            hideSelectedOptions={!rest.isMulti}
            components={
              rest.isMulti
                ? {
                    Option: ({ children, ...rest }) => (
                      <CustomOptionComponent {...rest}>
                        <div className="px-1 d-flex align-items-center">
                          <Check checked={rest.isSelected} name="check" />
                          {children}
                        </div>
                      </CustomOptionComponent>
                    ),
                  }
                : {}
            }
            {...rest}
          />
        </div>
        {rest?.name && rest?.formik && (
          <>{FormikError(rest.formik, rest.name)}</>
        )}
      </div>
    );
  }
);

export default SelectComponent;
