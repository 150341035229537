import * as yup from "yup";
import { isBottleneckTextAreaEnabled } from "../../views/pages/Add/OutbreakInformation/helpers";

export const RESPONSE_STATUS = {
  APPLICABLE: "APPLICABLE",
  DEFERRED: "DEFERRED",
  NOT_APPLICABLE: "NOT_APPLICABLE",
};

export const EVENT_BE_ASSESSED = [
  { label: "Yes", value: "YES" },
  { label: "Not sure", value: "Not sure" },
  { label: "No", value: "NO" },
];
export const responseValidationSchema = yup.object().shape({
  date_of_response_initiation: yup.date().nullable(),
  date_of_response_completion: yup.date().nullable(),
  response_bottlenecks: yup
    .array()
    .test(
      "isRequired",
      "Please select min one and max three elements",
      function (arr) {
        if (!arr || arr.length === 0) {
          // No elements selected
          return false;
        }

        // Check if other specifiy is selected
        const isOtherSpecifySelected = arr.some(
          (item) => item.value === "OTHER_SPECIFY"
        );

        if (isOtherSpecifySelected) {
          // other specifiy is selected, check the length
          if (arr.length > 1 && arr.length <= 3) {
            return true; // Valid selection
          } else if (arr.length === 1) {
            return this.createError({
              path: "response.response_bottlenecks",
              message:
                "When selecting other specifiy, please ensure another option along with other specifiy.",
            });
          }
          return false;
        }

        // other specifiy is not selected, check the length
        return arr.length >= 1 && arr.length <= 3;
      }
    )
    .nullable(),
  response_bottlenecks_text: yup
    .string()
    .when("response_bottlenecks", {
      is: (value) => isBottleneckTextAreaEnabled(value),
      then: yup.string().required("Required *").nullable(),
      otherwise: yup.string().nullable(),
    })
    .nullable(),
  response_enablers: yup.string().nullable(),
  response_narrative: yup.string().nullable(),

  response_action: yup
    .array()
    .of(
      yup.object().shape({
        description: yup.string().nullable(),
        status: yup
          .string()
          .default((_, context) => {
            return context.parent.indexOf(context.value) === 0
              ? RESPONSE_STATUS.APPLICABLE
              : yup.string().nullable();
          })
          .nullable(),
        date_of_initiation: yup.mixed().when("status", {
          is: (status) => status === RESPONSE_STATUS.APPLICABLE,
          then: yup.mixed().required("Required*").nullable(),
          otherwise: yup.mixed().notRequired().nullable(),
        }),
        date_of_completion: yup.mixed().when("status", {
          is: (status) => status === RESPONSE_STATUS.APPLICABLE,
          then: yup.mixed().required("Required*").nullable(),
          otherwise: yup.mixed().notRequired().nullable(),
        }),
      })
    )
    .transform((value) => {
      if (Array.isArray(value) && value.length > 0) {
        const ind = value.findIndex((item) => {
          return item.status === "APPLICABLE";
        });
        if (ind == -1) {
          value[0].status = RESPONSE_STATUS.APPLICABLE;
        } else {
          value[ind].status = RESPONSE_STATUS.APPLICABLE;
        }
      }
      return value;
    }),

  // response_action: yup.array().of(
  //   yup.object().shape({
  //     description: yup.string().nullable(),
  //     status: yup.string().nullable(),
  //     date_of_initiation: yup
  //       .mixed()
  //       .when("status", {
  //         is: (status) => status === RESPONSE_STATUS.APPLICABLE,
  //         then: yup.mixed().required("Required*").nullable(),
  //         otherwise: yup.mixed().notRequired().nullable(),
  //       })
  //       .nullable(),
  //     date_of_completion: yup
  //       .mixed()
  //       .when("status", {
  //         is: (status) => status === RESPONSE_STATUS.APPLICABLE,
  //         then: yup.mixed().required("Required*").nullable(),
  //         otherwise: yup.mixed().notRequired().nullable(),
  //       })
  //       .nullable(),
  //   })
  // ),
  event_be_assessed: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
});

export const responseWithoutValidationSchema = yup.object().shape({
  date_of_response_initiation: yup.date().nullable(),
  date_of_response_completion: yup.date().nullable(),
  response_bottlenecks: yup.array().nullable(),
  response_bottlenecks_text: yup.string().nullable(),
  response_enablers: yup.string().nullable(),
  response_action: yup.array().of(
    yup.object().shape({
      description: yup.string().nullable(),
      status: yup.string().nullable(),
      date_of_initiation: yup
        .mixed()
        .when("status", {
          is: (status) => status === RESPONSE_STATUS.APPLICABLE,
          then: yup.mixed().required("Required*").nullable(),
          otherwise: yup.mixed().notRequired().nullable(),
        })
        .nullable(),
      date_of_completion: yup.mixed().when("status", {
        is: (status) => status === RESPONSE_STATUS.APPLICABLE,
        then: yup.mixed().required("Required*").nullable(),
        otherwise: yup.mixed().notRequired().nullable(),
      }),
    })
  ),
  event_be_assessed: yup.mixed().nullable(),
});

export const responseValidationSchemaForOpenStatus = yup.object().shape({
  date_of_response_initiation: yup.date().nullable(),
  date_of_response_completion: yup.date().nullable(),
  response_bottlenecks: yup.array().nullable(),
  response_bottlenecks_text: yup.string().nullable(),
  response_enablers: yup.string().nullable(),
  response_action: yup.array().of(
    yup.object().shape({
      description: yup.string().nullable(),
      status: yup.string().nullable(),
      date_of_initiation: yup
        .mixed()
        .when("status", {
          is: (status) => status === RESPONSE_STATUS.APPLICABLE,
          then: yup.mixed().required("Required*").nullable(),
          otherwise: yup.mixed().notRequired().nullable(),
        })
        .nullable(),
      date_of_completion: yup.mixed().when("status", {
        is: (status) => status === RESPONSE_STATUS.APPLICABLE,
        then: yup.mixed().required("Required*").nullable(),
        otherwise: yup.mixed().notRequired().nullable(),
      }),
    })
  ),
  event_be_assessed: yup.mixed().nullable(),
});

export const responseInitialValues = {
  date_of_response_initiation: "",
  date_of_response_completion: "",
  response_bottlenecks: [],
  response_bottlenecks_text: "",
  response_enablers: "",
  response_narrative: "",
  event_be_assessed: "",
  response_action: [
    {
      description:
        "Initiate investigation or deploy investigation/response team",
      status: RESPONSE_STATUS.APPLICABLE,
      date_of_initiation: "",
      date_of_completion: "",
    },
    {
      description:
        "Conduct epidemiologic analysis of burden, severity and risk factors, and perform initial risk assessment",
      status: RESPONSE_STATUS.NOT_APPLICABLE,
      date_of_initiation: "",
      date_of_completion: "",
    },
    {
      description: "Obtain laboratory confirmation of the outbreak etiology",
      status: RESPONSE_STATUS.NOT_APPLICABLE,
      date_of_initiation: "",
      date_of_completion: "",
    },
    {
      description:
        "Initiate appropriate case management and infection prevention and control (IPC) measures in health facilities",
      status: RESPONSE_STATUS.NOT_APPLICABLE,
      date_of_initiation: "",
      date_of_completion: "",
    },
    {
      description:
        "Initiate appropriate public health countermeasures in affected communities",
      status: RESPONSE_STATUS.NOT_APPLICABLE,
      date_of_initiation: "",
      date_of_completion: "",
    },
    {
      description:
        "Initiate appropriate risk communication and community engagement activities",
      status: RESPONSE_STATUS.NOT_APPLICABLE,
      date_of_initiation: "",
      date_of_completion: "",
    },
    {
      description: "Establish a coordination mechanism",
      status: RESPONSE_STATUS.NOT_APPLICABLE,
      date_of_initiation: "",
      date_of_completion: "",
    },
  ],
};

export const responseInitial_keys = [
  { title: "Date of Response Initiation", key: "date_of_response_initiation" },
  { title: "Date of Response Completion", key: "date_of_response_completion" },
  { title: "Response Bottlenecks", key: "response_bottlenecks" },
  { title: "response_bottlenecks text", key: "response_bottlenecks_text" },
  { title: "Response Enablers", key: "response_enablers" },
  { title: "Response Narrative", key: "response_narrative" },
  {
    title: "Should the Event be assessed by IHR Focal Person?",
    key: "event_be_assessed",
  },
];
