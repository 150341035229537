import * as yup from "yup";

export const closureValidationSchema = yup.object().shape({
  date_of_outbreak_end: yup.mixed().nullable(),
  closure_narrative: yup.string().nullable(),
  attached_reports: yup.mixed().nullable(),
  event_status: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
});

export const closureWithoutValidationSchema = yup.object().shape({
  date_of_outbreak_end: yup.mixed().nullable(),
  closure_narrative: yup.string().nullable(),
  attached_reports: yup.mixed().nullable(),
  event_status: yup.mixed().nullable(),
});

export const closureValidationSchemaForCloseStatus = yup.object().shape({
  date_of_outbreak_end: yup.mixed().required("Required *").nullable(),
  closure_narrative: yup.string().nullable(),
  attached_reports: yup.mixed().nullable(),
  event_status: yup.mixed().nullable(),
});

export const closureInitialValues = {
  date_of_outbreak_end: "",
  closure_narrative: "",
  attached_reports: [],
  event_status: {
    label: "Open",
    value: "OPEN",
  },
};
export const closure_keys = [
  { title: "date_of_outbreak_end", key: "date_of_outbreak_end" },
  { title: "end_narrative", key: "closure_narrative" },
  { title: "attached_reports", key: "attached_reports" },
  {
    title: "Event Verification after Response?",
    key: "event_existence_established",
  },

  { title: "event status", key: "event_status" },
  { title: "Closed By", key: "closed_by" },
];
