import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { observer } from "mobx-react";
import { Row, Col, Card, CardBody } from "reactstrap";

import SelectComponent from "../../../components/common/select/Select";
import FileUploader from "../../../wasfaty/components/Add/FileUploader";
import DatePickerComponent from "../../../components/common/datePicker/DatePicker";
import TextInputCommon from "../../../components/common/input/TextInput";

import { selectThemeColors } from "@utils";
import { IntlContext } from "../../../../utility/context/IntelContext";
import { RegionSiteModal } from "../../../wasfaty/Models/RegionSiteModal";
import { RegionService } from "../../../wasfaty/services";

import "./style.scss";
import { SC } from "../../../ESurvey/Helper/helper";
import {
  canComplete,
  canCompleteClose,
  canCreateClosure,
  canOpenClosureDropdown,
  canUpdateClosure,
  checkFormStatus,
  getDateTimeFormat,
} from "../OutbreakInformation/helpers";
import debounce from "lodash/debounce";
import { useParams } from "react-router-dom";
import Check from "../../../components/common/check/Check";
import moment from "moment";

const clouser = observer(
  ({ item, handleChange, siteType, except = [], formik }) => {
    const ref = useRef(null);
    const [dropDrowns, setDropDrowns] = useState(undefined);
    const [regions, setRegions] = useState([]);
    const [check, setCheck] = useState(false);
    const [sitesModal] = useState(() => new RegionSiteModal({ siteType }));
    const intl = useIntl();
    const intlContext = useContext(IntlContext);
    const isEn = intlContext.locale === "en";
    const region_id = item?.region?._id;
    const { id } = useParams();

    const getTranslation = (text) => {
      return intl.formatMessage({
        id: text,
        defaultMessage: text,
      });
    };

    useEffect(() => {
      getDropDowns();
      getRegions();
    }, []);

    const getRegions = () => {
      RegionService.getByMethod("Pharmacy").then(setRegions);
    };
    const getDropDowns = () => {
      SC.getCall("outbreak/formOptions?step=CLOSURE").then((res) => {
        if (res?.data) setDropDrowns(res.data);
      });
    };
    // useEffect(() => {
    //   if (region_id) {
    //     sitesModal.getSites(region_id);
    //     if (region_id != item?.site?.region_id) item.setAttribute("site", null);
    //   }
    // }, [region_id]);

    const FileUploadChnage = useCallback(

      debounce((name, e) => {
        console.log(e,"ename");
        
        formik.setFieldValue("closure.attached_reports", e);
      }, 1000),
      [formik.values]
    );

    const isDisabled = () => {
      const formStatus = formik.values?.closure?.event_status?.value;
      return id
        ? checkFormStatus(formStatus) || !canUpdateClosure
        : !canCreateClosure;
    };
    const isDisabledEventExistence = () => {
      const formStatus = formik.values?.closure?.event_status?.value;
      if (
        formik.values?.notification?.event_existence_established?.value ===
          "Verified" &&
        !formik.values?.notification?.event_existence_established_check
      )
        return true;
      return id
        ? checkFormStatus(formStatus) || !canUpdateClosure
        : !canCreateClosure;
    };
    const isResponseAction = (value) => {
      if (value === "COMPLETED" || value === "CLOSED") {
        const applicableExist = formik.values?.response.response_action?.some(
          (item) => {
            return item.status === "APPLICABLE";
          }
        );
        if (!applicableExist) {
          formik.setFieldValue(
            `response.response_action[0].status`,
            "APPLICABLE"
          );
        }
      }
    };

    return (
      <Row>
        <Card>
          <CardBody>
            <Row>
              <Col className="mb-1" md="12" sm="12">
                <DatePickerComponent
                  label="Date & time of event end"
                  placeholder=""
                  name="closure.date_of_outbreak_end"
                  value={formik.values?.closure?.date_of_outbreak_end}
                  formik={formik}
                  handleChange={([date]) => {
                    formik.setFieldValue(
                      "closure.date_of_outbreak_end",
                      getDateTimeFormat(date)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  options={{
                    enableTime: true,
                    time_24hr: true,
                    dateFormat: "Y-m-d H:i",
                    minDate: moment(
                      formik.values?.response?.date_of_response_completion
                    ).format("YYYY-MM-DD HH:mm:ss"),
                  }}
                  isDisable={
                    isDisabled() ||
                    !formik.values?.response?.date_of_response_completion
                  }
                />
              </Col>
              <Col className="mb-1" md="12" sm="12">
                <TextInputCommon
                  value={formik.values?.closure?.closure_narrative}
                  name="closure.closure_narrative"
                  label="End narrative"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  disabled={isDisabled()}
                />
              </Col>

              <Col className="mb-1" md="12" sm="12">
                <FileUploader
                  ref={ref}
                  key={formik.values?.closure?.attached_reports?.length}
                  formik={formik}
                  images={formik.values?.closure?.attached_reports}
                  name="closure.attached_reports"
                  handleChange={FileUploadChnage}
                  onBlur={formik.handleBlur}
                  isAllFiles
                  isFileLimit={false}
                  disabled={isDisabled() || !check}
                />
                <div style={{ display: "flex" }}>
                  <Check
                    checked={check}
                    handleChange={(e) => setCheck(e.target.checked)}
                    name="check"
                  />
                  <div>
                    I acknowledge and accept that I will not upload any
                    personally identifiable information, such as names, ID
                    numbers, addresses, or phone numbers. I understand that
                    uploading such information may result in a violation of
                    privacy and security policies.
                  </div>
                </div>
              </Col>
              <Col className="mb-1" md="12" sm="12">
                <SelectComponent
                  label="Event Verification after Response?*"
                  name="notification.event_existence_established"
                  value={
                    formik.values?.notification?.event_existence_established
                  }
                  formik={formik}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "notification.event_existence_established",
                      value
                    );
                    formik.setFieldValue(
                      "notification.event_existence_established_check",
                      true
                    );
                  }}
                  onBlur={(e) => {
                    formik.setFieldTouched(
                      "notification.event_existence_established",
                      true
                    );
                  }}
                  options={["Not verified", "Verified"].map((item) => {
                    return { label: item, value: item };
                  })}
                  placeholder={intl.formatMessage({
                    id: "Select",
                    defaultMessage: "Select",
                  })}
                  theme={selectThemeColors}
                  className="react-select custom-select"
                  // classNamePrefix="select"
                  isDisabled={isDisabledEventExistence()}
                />
              </Col>
              <Col className="mb-1" md="12" sm="12">
                <SelectComponent
                  label="Event Status"
                  name="closure.event_status"
                  value={formik.values?.closure?.event_status}
                  formik={formik}
                  onChange={(value) => {
                    isResponseAction(value?.value);
                    formik.setFieldValue("closure.event_status", value);
                  }}
                  onBlur={(e) => {
                    formik.setFieldTouched("closure.event_status", true);
                  }}
                  options={dropDrowns?.EVENT_STATUS?.map((item) => {
                    return {
                      label: getTranslation(item.label),
                      value: item.value,
                      isDisabled: !canCompleteClose(item?.value),
                    };
                  })}
                  placeholder={intl.formatMessage({
                    id: "Select",
                    defaultMessage: "Select",
                  })}
                  theme={selectThemeColors}
                  className="react-select custom-select"
                  classNamePrefix="select"
                  isDisabled={!canOpenClosureDropdown}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    );
  }
);

export default clouser;
