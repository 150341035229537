import moment from "moment";
import * as yup from "yup";
import {
  isShowCertainFacility,
  isTextAreaEnabled,
} from "../../views/pages/Add/OutbreakInformation/helpers";

export const emerganceValidationSchema = yup.object().shape({
  event_ID: yup.string().required("Required *").nullable(),
  // date_of_creation: yup.mixed().required("Required *").nullable(),
  disease_id: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
  responsible_department: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
  event_type: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value?.length >= 1;
    })
    .nullable(),

  date_of_emergence: yup.mixed().required("Required *").nullable(),
  epi_week: yup.string().required("Required *").nullable(),
  health_directorate: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
  // health_facilities: yup
  //   .mixed()
  //   .test("isRequired", "Please select a value", function (value) {
  //     return value !== null && value !== undefined && value !== "";
  //   })
  //   .nullable(),
  longitude: yup.string().nullable(),
  address: yup.string().nullable(),
  narrative: yup.string().nullable(),
  association_with_certain_facility: yup.mixed().nullable(),
  association_with_public_events: yup.array().nullable(),
  health_cluster: yup.array().nullable(),
  non_health_facility: yup
    .mixed()
    // .test("isRequired", "Please select a value", function (value) {
    //   return value !== null && value !== undefined && value !== "";
    // })
    .nullable(),
  // .test("isRequired", "Please select minimum three elements", function (arr) {
  // return arr?.length === 0 || arr?.length >= 3;
  // }),
  association_with_certain_facility_text: yup
    .mixed()
    // .when("association_with_certain_facility", {
    //   is: (value) => {
    //     console.log("value of the certain facility", value);
    //     return isTextAreaEnabled(value?.value);
    //   },
    //   then: yup
    //     .mixed()

    //     .required("Required *"),
    //   otherwise: yup.mixed(),
    // })
    .nullable(),
  // association_with_certain_facility_free_text: yup
  //   .mixed()
  //   .when("event_type", {
  //     is: (value) => isTextAreaEnabled(value?.value),
  //     then: yup
  //       .string()

  //       .required("Required *"),
  //     otherwise: yup.string(),
  //   })
  //   .nullable(),
});

export const emerganceWithoutValidationSchema = yup.object().shape({
  event_ID: yup.string().nullable(),
  date_of_creation: yup.mixed().nullable(),
  disease_id: yup.mixed().nullable(),
  responsible_department: yup.mixed().nullable(),
  event_type: yup.mixed().nullable(),
  date_of_emergence: yup.mixed().nullable(),
  epi_week: yup.string().nullable(),
  health_directorate: yup.mixed().nullable(),
  // health_facilities: yup.mixed().nullable(),
  longitude: yup.string().nullable(),
  address: yup.string().nullable().nullable(),
  narrative: yup.string().nullable(),
  association_with_certain_facility: yup.mixed().nullable(),
  association_with_certain_facility_text: yup.mixed().nullable(),
  association_with_certain_facility_free_text: yup.string().nullable(),
  association_with_public_events: yup.array().nullable(),
  health_cluster: yup.array().nullable(),
  non_health_facility: yup.mixed().nullable(),
});

export const emerganceValidationSchemaForOpenStatus = yup.object().shape({
  event_ID: yup.string().nullable(),
  date_of_creation: yup.mixed().nullable(),
  disease_id: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
  responsible_department: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
  event_type: yup.mixed().nullable(),
  date_of_emergence: yup.mixed().nullable(),
  epi_week: yup.string().nullable(),
  health_directorate: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
  // health_facilities: yup.mixed().nullable(),
  longitude: yup.string().nullable(),
  address: yup.string().nullable().nullable(),
  narrative: yup.string().nullable(),
  association_with_certain_facility: yup.mixed().nullable(),
  association_with_certain_facility_text: yup.mixed().nullable(),
  association_with_certain_facility_free_text: yup.string().nullable(),
  association_with_public_events: yup.array().nullable(),
  health_cluster: yup.array().nullable(),
  non_health_facility: yup.mixed().nullable(),
});

export const emerganceInitialValues = {
  event_ID: "EVNXX78",
  date_of_creation: new Date(),
  disease_id: "",
  responsible_department: "",
  event_type: "",
  date_of_emergence: "",
  epi_week: "",
  health_directorate: "",
  health_facilities: "",
  location_link: "",
  address: "",
  association_with_certain_facility: "",
  association_with_certain_facility_text: [],
  association_with_certain_facility_free_text: "",
  association_with_public_events: [],
  health_cluster: [],
  narrative: "",
  non_health_facility: "",
};

export const emergence_keys = [
  { title: "Event Id", key: "event_ID" },
  { title: "Submission Date & Time", key: "date_of_creation" },
  { title: "Event Type", key: "disease_id" },
  { title: "Responsible Department", key: "responsible_department" },
  { title: "Event", key: "event_type" },
  { title: "Date & time of emergence", key: "date_of_emergence" },
  { title: "Epi-week", key: "epi_week" },
  { title: "Health Directorate", key: "health_directorate" },
  { title: "Health Cluster", key: "health_cluster" },
  // { title: "Health Facility", key: "health_facilities" },
  { title: "Emergence Narrative", key: "narrative" },
  { title: "Google Map", key: "location_link" },
  { title: "address", key: "address" },
  {
    title: "association with certain facility",
    key: "association_with_certain_facility",
  },
  {
    title: "Health facilities",
    key: "association_with_certain_facility_text",
  },
  {
    title: "Non-health facility Name",
    key: "association_with_certain_facility_free_text",
  },
  {
    title: "Association with public events",
    key: "association_with_public_events",
  },
  {
    title: "Type of Non Health Facility",
    key: "non_health_facility",
  },
  {
    title: "Created By",
    key: "created_by",
  },
];
