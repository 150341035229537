import React, { useState, useContext, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { selectThemeColors } from "@utils";
import { FormattedMessage, useIntl } from "react-intl";
import { Row, Col, Label, Card, CardBody, Button, Input, UncontrolledTooltip } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";

import MultiSites from "../components/twoTogglerButton";
import TextInputCommon from "../../../components/common/input/TextInput";
import DatePickerComponent from "../../../components/common/datePicker/DatePicker";
import SelectComponent from "../../../components/common/select/Select";
import get from "lodash/get";
import startCase from "lodash/startCase";

import { RegionSiteModal } from "../../../wasfaty/Models/RegionSiteModal";
import { IntlService, RegionService } from "../../../wasfaty/services";
import List from "../../../wasfaty/components/listing/List";
import { useParams } from "react-router-dom";
import isEqual from "lodash/isEqual";

import "./style.scss";
import {
  FormikError,
  canCreateDetection,
  canUpdateDetection,
  checkFormStatus,
  getDateTimeFormat,
  isBottleneckTextAreaEnabled,
  isTextAreaEnabled,
} from "../OutbreakInformation/helpers";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import moment from "moment";
import FilterBar from "../../List/components/FilterBar/FilterBar";
import { SC } from "../../../wasfaty/Api/serverCall";
import PaginationFooter from "../../../wasfaty/components/listing/PaginationFooter";
import { AlertCircle } from "react-feather";
// import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";
const multi_sites_data = [
  {
    event_id: "123",
    _id: "33543",
    disease: {
      disease_name_en: "test",
    },
    region: "jiaz",
    date_of_detection: "12-10-2016",
    date_of_notification: "20-05-2023",
  },
  {
    event_id: "124",
    _id: "33545",
    disease: {
      disease_name_en: "test",
    },
    region: "jiaz",
    date_of_detection: "12-10-2016",
    date_of_notification: "20-05-2023",
  },
];
// let multiEventsSelectedRows = [];

export const Signature = observer(({ inspector, disabled }) => {
  return (
    <div className="signature">
      <SignatureCanvas
        ref={(ref) => {
          if (disabled) {
            ref?.off?.();
          }
          try {
            inspector.setSignatureRef(ref);
          } catch (error) {}
        }}
        penColor="black"
        canvasProps={{
          className: "signature-canvas",
        }}
        clearOnResize={false}
        onEnd={() => inspector.setValues()}
      />
    </div>
  );
});
const Detection = observer(
  ({ item, handleChange, siteType, except = [], formik }) => {
    const { id } = useParams();
    const [dropDrowns, setDropDrowns] = useState(undefined);

    const [multiSiteLoading, setMultisiteLoading] = useState(false);
    const [multiSitesListing, setMultiSitesListing] = useState([]);
    const [multiEventsSelectedRows, setMultiEventsSelectedRows] = useState([]);
    const [check, setCheck] = useState(false);
    const intl = useIntl();

    const [pagination, setpagination] = useState({
      page: 1,
      pageCount: 0,
      to: 0,
      totalPages: 0,
    });
    const [filter, setFilter] = useState({
      search: "",
      fromDate: "",
      toDate: "",
    });
    const getTranslation = (text) => {
      return intl.formatMessage({
        id: text,
        defaultMessage: text,
      });
    };

    const getDropDowns = () => {
      SC.getCall({ url: "outbreak/formOptions?step=DETECTION" }).then((res) => {
        if (res?.data) setDropDrowns(res.data);
      });
    };

    const getMultiSitesListing = (query = { selected: 0 }, filters = null) => {
      setCheck(true);
      const params = {
        page: query.selected + 1,
        per_page: 10,
        filters,
        ...filter,
      };
      SC.getCall({
        url: `outbreak/multiLinkListing?isPagination=1`,
        params,
      }).then((res) => {
        if (res?.status === 200 && res?.data?.data) {
          setMultiSitesListing(res.data.data?.data);
          // const selectedRowsFromPreviousPage = multiEventsSelectedRows.filter((row) =>
          //   res.data.data?.data.find((newRow) => newRow._id === row)
          // );
          // setMultiEventsSelectedRows(selectedRowsFromPreviousPage);

          const _pagination = res.data.data;
          const page = _pagination.current_page;
          const perpage = _pagination.per_page;
          const totalPages = _pagination.total;
          const pageCount = Math.ceil(totalPages / perpage);

          const to = _pagination.to;

          setpagination({
            page,
            pageCount,
            totalPages,
            to,
          });
        }
      });
      setCheck(false);
    };
    useEffect(() => {
      getDropDowns();
    }, []);

    useEffect(() => {
      getMultiSitesListing();
    }, [formik.values?.emergence?.disease_id]);
    // useEffect(() => {
    //   if (dropDrowns?.DETECTION_BOTTLENECKS?.length) {
    //     if (formik.values?.detection?.detection_bottlenecks.length)
    //       setDropDrowns((pre) => {
    //         return {
    //           ...pre,
    //           DETECTION_BOTTLENECKS: pre.DETECTION_BOTTLENECKS.map((option) => {
    //             console.log("bott ----------------", option?.label);
    //             if (option.label.toLowerCase() === "none") {
    //               return {
    //                 ...option,
    //                 options: option.options?.map((item) => ({
    //                   ...item,
    //                   disabled: true,
    //                 })),
    //               };
    //             }
    //             return option;
    //           }),
    //         };
    //       });
    //     else
    //       setDropDrowns((pre) => {
    //         return {
    //           ...pre,
    //           DETECTION_BOTTLENECKS: pre.DETECTION_BOTTLENECKS.map((option) => {
    //             console.log("bott ----------------", option?.label);
    //             if (option.label.toLowerCase() === "none") {
    //               return {
    //                 ...option,
    //                 options: option.options?.map((item) => ({
    //                   ...item,
    //                   disabled: false,
    //                 })),
    //               };
    //             }
    //             return option;
    //           }),
    //         };
    //       });
    //   }
    // }, [formik.values?.detection?.detection_bottlenecks]);
    // console.log("bottlenecks", dropDrowns?.DETECTION_BOTTLENECKS);
    const handleCheckboxChange = (rowId) => {
      // Check if rowId is already in the array
      let prevRows = [...formik.values?.detection?.multi_sites_id];
      const index = prevRows.indexOf(rowId);

      if (index !== -1) {
        // If it is, remove it
        const updatedRows = [
          ...prevRows.slice(0, index),
          ...prevRows.slice(index + 1),
        ];
        formik.setFieldValue("detection.multi_sites_id", updatedRows);
      } else {
        // If it's not, add it
        formik.setFieldValue("detection.multi_sites_id", [...prevRows, rowId]);
      }
    };

    const basicColumns = [
      // {
      //   name: "",
      //   sortable: false,
      //   width: "20px",
      //   cell: (row) => {
      //     return (
      //       <Label check>
      //         <Input
      //           type="checkbox"
      //           checked={formik.values?.detection?.multi_sites_id.includes(
      //             row._id
      //           )}
      //           onChange={() => handleCheckboxChange(row?._id)}
      //           style={{ width: "14px", height: "14px" }}
      //         />
      //       </Label>
      //     );
      //   },
      // },
      {
        name: "MS Event ID",
        // sortable: true,
        cell: (row) => {
          return (
            <span className="font-weight-bold text-truncate">{row?.msId}</span>
          );
        },
      },
      {
        name: "Event ID",
        // sortable: true,
        cell: (row) => {
          return (
            <span className="font-weight-bold text-truncate">
              {row?.multi_link?.map((item) => (
                <span>{`${item?.event_ID}, `}</span>
              ))}
            </span>
          );
        },
      },
      {
        name: "Disease",
        // sortable: true,
        cell: (row) => {
          return (
            <span className="font-weight-bold text-truncate">
              {row?.multi_link?.map((item) => (
                <span>{`${
                  item?.disease?.disease_name_en
                    ? item.disease.disease_name_en
                    : ""
                } `}</span>
              ))}
            </span>
          );
        },
      },
      {
        name: "Region",
        // sortable: true,
        cell: (row) => {
          return (
            <span className="font-weight-bold text-truncate">
              {row?.multi_link?.map((item) => (
                <span>{`${
                  item?.health_directorate?.name
                    ? item.health_directorate.name
                    : ""
                } `}</span>
              ))}
            </span>
          );
        },
      },

      {
        name: "Date of notification",
        // sortable: true,
        cell: (row) => {
          return (
            <span className="font-weight-bold text-truncate">
              {moment(get(row, "created_at", "")).format("ll")}
            </span>
          );
        },
      },
    ];
    const handleSelectRows = ({ selectedRows }) => {
      console.log("selectedRows", selectedRows);

      // Check if the selectedRows array has actually changed before updating state
      // if (!isEqual(multiEventsSelectedRows, selectedRows)) {
      if (!check) {
        setMultiEventsSelectedRows(selectedRows);
        formik.setFieldValue(
          "detection.multi_sites_id",
          selectedRows?.map((item) => item._id)
        );
      }
      // }
    };

    // const selectableRowSelected = useMemo(
    //   () => (row) => {
    //     return formik.values?.detection?.multi_sites_id.includes(row._id);
    //   },
    //   [formik.values?.detection?.multi_sites_id]
    // );

    const selectableRowSelected = useMemo(
      () => (row) => {
        if (formik.values?.detection?.multi_sites_id)
          return formik.values?.detection?.multi_sites_id.includes(row._id);
      },
      [id, formik.values?.detection?.multi_sites]
    );

    const isLinkBttonEnabled = () => {
      if (canCreateDetection && multiEventsSelectedRows?.length > 0) {
        return true;
      }
      if (id && canUpdateDetection) {
        return true;
      }
      return false;
    };

    const isDisabled = () => {
      const formStatus = formik.values?.closure?.event_status?.value
      return id ? (checkFormStatus(formStatus) || !canUpdateDetection) : !canCreateDetection;
    }

    const msg = () => {
      if (!formik.values?.notification?.date_of_notification) {
        return 'Please select the notification date first';
      }
    }

    return (
      <Row>
        <Card>
          <CardBody>
            <Row>
              <Col className="mb-1" md="12" sm="12">

                <UncontrolledTooltip placement="top" target="date_of_detection" innerClassName="custom-tooltip">
                            •  Date the event is first identified/recorded by any source or in any point in the health care system. 
                          </UncontrolledTooltip>
              
                          {/* Label container */}
                          <div 
                            id="date_of_detection" 
                            className="d-inline-flex align-items-center"
                            style={{ paddingInline: '5px', width: 'fit-content' }}
                          >
                            <AlertCircle size={18} className="cursor-pointer" style={{marginTop: -5}} />
                            <label htmlFor="datePicker" className="form-label" style={{marginLeft: 5}}>Date of detection*</label>
                          </div>
                <DatePickerComponent
                  // label="Date of detection*"
                  placeholder="yyyy-mm-dd | 24:45"
                  name="detection.date_of_detection"
                  value={formik.values?.detection?.date_of_detection}
                  formik={formik}
                  handleChange={([date]) => {
                    formik.setFieldValue(
                      "detection.date_of_detection",
                      getDateTimeFormat(date)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    time_24hr: true,
                    maxDate: moment(formik.values?.notification?.date_of_notification).format('YYYY-MM-DD HH:mm:ss')
                  }}
                  isDisable={isDisabled() || !formik.values?.notification?.date_of_notification}
                />
                <div style={{color: 'red', marginTop: 20, marginLeft: 10, fontSize: 20, fontWeight: 'bold' }}>{msg()}</div>
              </Col>
              <Col className="mb-1" md="12" sm="12">
                <TextInputCommon
                  type="textarea"
                  value={formik.values?.detection?.detection_narrative}
                  name="detection.detection_narrative"
                  label="Detection Narrative"
                  placeholder="Type Here"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  rows="5"
                  disabled={isDisabled()}
                />
              </Col>
              <Col className="mb-1" md="12" sm="12">
                <SelectComponent
                  label="Detection Bottlenecks*"
                  name="detection.detection_bottlenecks"
                  value={formik.values?.detection?.detection_bottlenecks}
                  formik={formik}
                  onChange={(value) => {
                    const hasNoBottlenecksOption =
                      formik.values?.detection?.detection_bottlenecks?.some(
                        (option) => option.value === "NO_BOTTLENECKS"
                      );
                    const hasNoBottlenecksOptionInValue = value?.some(
                      (option) => option.value === "NO_BOTTLENECKS"
                    );
                    if (
                      value.length > 1 &&
                      value[value.length - 1].value === "NO_BOTTLENECKS"
                    ) {
                      console.log("can not select bottleneck");
                    } else if (
                      (!hasNoBottlenecksOption ||
                        !hasNoBottlenecksOptionInValue) &&
                      value.length <= 3
                    ) {
                      formik.setFieldValue(
                        "detection.detection_bottlenecks",
                        value
                      );
                    }
                  }}
                  onBlur={(e) => {
                    formik.setFieldTouched(
                      "detection.detection_bottlenecks",
                      true
                    );
                  }}
                  options={dropDrowns?.DETECTION_BOTTLENECKS || []}
                  placeholder={intl.formatMessage({
                    id: "Select",
                    defaultMessage: "Select",
                  })}
                  theme={selectThemeColors}
                  className="react-select custom-select"
                  classNamePrefix="select"
                  isOptionDisabled={(option) => option.disabled}
                  isMulti
                  isDisabled={isDisabled()}
                />
              </Col>
              {isBottleneckTextAreaEnabled(
                formik.values?.detection?.detection_bottlenecks
              ) && (
                <Col className="mb-1" md="12" sm="12">
                  <TextInputCommon
                    value={formik.values?.detection?.detection_bottlenecks_text}
                    name="detection.detection_bottlenecks_text"
                    label="Other Specify*"
                    placeholder="Other Specify"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    disabled={isDisabled()}
                  />
                </Col>
              )}
              <Col className="mb-1" md="12" sm="12">
                <TextInputCommon
                  value={formik.values?.detection?.detection_enablers}
                  name="detection.detection_enablers"
                  label="Detection Enablers"
                  placeholder="Detection Enablers"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  disabled={isDisabled()}
                />
              </Col>

              <Col className="mb-1" md="12" sm="12">
                <Label className="form-label mt-1">
                  <FormattedMessage
                    id="Multi-Sites"
                    defaultMessage={"Multi-Sites"}
                  />
                </Label>
                <MultiSites
                  name="detection.multi_sites"
                  value={formik.values?.detection?.multi_sites}
                  handleChange={formik.setFieldValue}
                  isDisabled
                />
                {/* {FormikError(formik, "detection.multi_sites_id")} */}
                <p
                  title={""}
                  style={{
                    position: "absolute",
                    bottom: "-35px",
                    left: "13px",
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "red",
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {id && formik.values?.detection?.multi_sites !== "applicable"
                    ? "No forms are linked"
                    : ""}
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/* {formik.values?.detection?.multi_sites === "applicable" && (
          <div className="d-flex justify-content-end">
            <div className="">
              <Button
                className=" import-btn"
                color="primary"
                onClick={() => {
                  toast.success("Linked successfully!");
                }}
                disabled={!isLinkBttonEnabled()}
              >
                <span className="align-middle">Link</span>
              </Button>
            </div>
          </div>
        )} */}

        {formik.values?.detection?.multi_sites === "applicable" && (
          <>
            {/* <FilterBar getAllItems={getMultiSitesListing} noExport /> */}
            <DataTable
              noHeader
              noDataComponent={
                <div className="p-2">
                  {IntlService.m(
                    multiSiteLoading
                      ? "Loading Data..."
                      : "There are no records to display"
                  )}
                </div>
              }
              // pagination/
              selectableRows={false}
              columns={basicColumns}
              data={formik.values.detection.multi_site_listing}

              // selectableRowSelected={selectableRowSelected}
              // onSelectedRowsChange={(row) => handleSelectRows(row)}
              // pagination
              // paginationServer
              // paginationTotalRows={pagination.totalPages}
              // onChangePage={(page) =>
              //   getMultiSitesListing({ selected: page - 1 }, null)
              // }
            />
            {/* <PaginationFooter
              pagination={pagination}
              handlePageChange={getMultiSitesListing}
            /> */}
          </>
        )}
      </Row>
    );
  }
);

export default Detection;
