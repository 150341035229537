import React, { useState } from "react";

import "./miandashboardstyle.scss";
import {
  closedIcon,
  completedIcon,
  openCaseIcon,
  precentageIcon,
  refreshIcon,
  reopenedIcon,
  triangleIcon,
} from "../../../assets/wasfaty/SVG";
import { Col, Row, Spinner } from "reactstrap";
import CustomBarChart from "../../components/CustomBarChart/CustomBarChart";
import { SC } from "../../wasfaty/Api/serverCall";
import { useEffect } from "react";

import startCase from "lodash/startCase";
import FilterDropDown from "../../components/FilterDropDown/FilterDropDown";
import FilterBar from "../List/components/FilterBar/FilterBar";
import { OutbreakInformationService } from "../../wasfaty/services";
function MainDashboard() {
  const [data, setdData] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);


  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pagination, setpagination] = useState({
    page: 1,
    pageCount: 0,
    to: 0,
    totalPages: 0,
  });

  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });

    console.log(selectedRegion?.map((region) => region._id), "selectedRegion");

    const getDashbaordData = (_, filters = null) => {
      setLoading(true);
      const allFilters = selectedRegion?.map((region) => region._id);
      console.log('filters----', filters);
      SC.getCall({
        url: "dashboard",
        params: {
        
          filters,
        },
      }).then((res) => {
        setdData(res.data);
        setLoading(false);
      });
    };
  
    // Trigger API call when region or filters change
    useEffect(() => {
      getDashbaordData();
    }, [selectedRegion]); // selectedRegion changes trigger re-fetch
  
   

  const icons = {
    reopened: reopenedIcon,
    open: openCaseIcon,
    completed: completedIcon,
    closed: closedIcon,
  };

  if (!data) return null;

  return (
    <div>
      {/* <div className="card-rounded d-flex justify-content-between align-items-center">
        <div>
          <FilterDropDown
            options={data.regions.map((item) => {
              return { ...item, label: item.name, value: item._id };
            })}
            onSelect={setSelectedRegion}
            selectedRegion={selectedRegion}
          />
        </div>

        <div className="d-flex gap-1 align-items-center">
          {loading ? <Spinner /> : null}
          <div className="cursor-pointer" onClick={() => getDashbaordData()}>
            {refreshIcon}
          </div>
        </div>
      </div> */}
      <div>
      <FilterBar getAllItems={getDashbaordData} options={data.regions.map((item) => {
              return { ...item, label: item.name, value: item._id };
            })} />
    
      </div>


      <div className="card-rounded mt-1 ">
        <div className="d-flex justify-content-between align-items-center statis-section">
          <p className="title">Statistics </p>
          {/* <div className="statis-badge">
            <figure>{triangleIcon}</figure>
            <p>13%</p>
          </div> */}
        </div>

        <Row className="mt-2 ">
          {Object.entries(data?.statistics).map(([key, value]) => (
            <Col md="3" className="widget-card">
              <figure>{icons[key]}</figure>

              <div className="">
                <p className="count">{value}</p>
                <p className="type">{startCase(key)}</p>
              </div>
            </Col>
          ))}
        </Row>

        <div></div>
      </div>

      <div className=" mt-1">
        <div className=" graph-containers">
          <div className="  graph">
            <div className=" card-rounded">
              <CustomBarChart
                region_id={selectedRegion?.value}
                data={data.detection}
                title="detection"
              />
            </div>
          </div>
          <div className="   graph">
            <div className=" card-rounded">
              <CustomBarChart
                region_id={selectedRegion?.value}
                data={data.notification}
                title="notification"
              />
            </div>
          </div>
          <div className="   graph">
            <div className=" card-rounded">
              <CustomBarChart
                region_id={selectedRegion?.value}
                data={data.response}
                title="response"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainDashboard;
