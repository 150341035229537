import Identification from "../Identification";
import Detection from "../Detection";
import Notification from "../Notification";
import Closure from "../Closure";
import Response from "../Response";
import ListLine from "../Listline";
import { useIntl } from "react-intl";
import startCase from "lodash/startCase";
import { emerganceValidationSchema } from "../../../../utility/constants/Emergence";
import { detectionValidationSchema } from "../../../../utility/constants/Detection";
import { notificationValidationSchema } from "../../../../utility/constants/Notification";
import { responseValidationSchema } from "../../../../utility/constants/Response";
import { lineListValidationSchema } from "../../../../utility/constants/LineList";
import { closureValidationSchema } from "../../../../utility/constants/closure";
import { getUserData } from "@utils";

import moment from "moment";
const _ = require("lodash");

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const getTranslation = (text) => {
  const intl = useIntl();
  return intl.formatMessage({
    id: text,
    defaultMessage: text,
  });
};

const tabs = (item, handleChange, formik, editData) => ({
  emergence: (
    <Identification
      item={item}
      handleChange={handleChange}
      formik={formik}
      editData={editData}
    />
  ),
  detection: (
    <Detection
      item={item}
      handleChange={handleChange}
      formik={formik}
      editData={editData}
    />
  ),
  notification: (
    <Notification
      item={item}
      handleChange={handleChange}
      formik={formik}
      editData={editData}
    />
  ),
  response: (
    <Response
      item={item}
      handleChange={handleChange}
      formik={formik}
      editData={editData}
    />
  ),
  closure: (
    <Closure
      item={item}
      handleChange={handleChange}
      formik={formik}
      editData={editData}
    />
  ),
  line_list: (
    <ListLine
      item={item}
      handleChange={handleChange}
      formik={formik}
      editData={editData}
    />
  ),
});
export const stepComponent = (key, item, handleChange, formik, editData) => ({
  id: key,
  title: getTranslation(startCase(key)),
  content: tabs(item, handleChange, formik, editData)[key],
});

export const FormikError = (formik, name, check) => {
  const properties = name.split(".");
  let currentErrorObj = formik?.errors;
  let currentTouchObj = formik?.touched;
  for (const property of properties) {
    if (currentErrorObj?.hasOwnProperty(property)) {
      currentErrorObj = currentErrorObj[property];
    } else return null;
  }
  for (const property of properties) {
    if (currentTouchObj?.hasOwnProperty(property)) {
      currentTouchObj = currentTouchObj[property];
    } else return null;
  }
  if (check) return currentErrorObj
  return (
    <p
      title={currentErrorObj}
      style={{
        position: "absolute",
        bottom: "-35px",
        left: "10px",
        fontSize: "12px",
        fontWeight: 500,
        color: "red",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left",
      }}
    >
      {currentErrorObj}
    </p>
  );
};

export const getValdationSchemaForActiveTab = (activeTab) => {
  if (!activeTab) return undefined;
  if (activeTab.toLowerCase() === "emergence") return emerganceValidationSchema;
  else if (activeTab.toLowerCase() === "detection")
    return detectionValidationSchema;
  else if (activeTab.toLowerCase() === "notification")
    return notificationValidationSchema;
  else if (activeTab.toLowerCase() === "response")
    return responseValidationSchema;
  else if (activeTab.toLowerCase() === "line_list")
    return lineListValidationSchema;
  else if (activeTab.toLowerCase() === "closure")
    return closureValidationSchema;
  return undefined;
};

// export const moveToNextTab = async (activeTab, formik) => {
//   if (getValdationSchemaForActiveTab(activeTab) && formik.values[activeTab]) {
//     try {
//       await getValdationSchemaForActiveTab(activeTab).validate(
//         formik.values[activeTab],
//         {
//           abortEarly: false,
//         }
//       );
//       return true;
//     } catch (errors) {
//       const formikErrors = {};
//       const formikTouched = {};
//       for (const key in getValdationSchemaForActiveTab(activeTab).fields) {
//         console.log(
//           "formik.values[activeTab][key],",
//           key
//         );
//         try {
//           await getValdationSchemaForActiveTab(activeTab).fields[key].validate(
//             formik.values[activeTab][key],
//             {
//               abortEarly: false,
//             }
//           );
//         } catch (error) {
//           console.log("formikErrors", error);
//           formikErrors[key] = error.message;
//           formikTouched[key] = true;
//         }
//       }

//       // Set errors for the "emergance" fields using setErrors
//       formik.setErrors({
//         [`${activeTab.toLowerCase()}`]: formikErrors,
//       });
//       for (const key in formikTouched) {
//         formik.setFieldTouched(`${activeTab.toLowerCase()}.${key}`, true);
//       }

//       console.log(errors);
//       return false;
//     }
//   }
// };
export const moveToNextTab = async (activeTab, formik) => {
  const validationSchema = getValdationSchemaForActiveTab(activeTab);
  const values =
    formik.values[activeTab === "line_list" ? "lineList" : activeTab];
  try {
    await validationSchema.validate(values, {
      abortEarly: false,
    });
    return true;
  } catch (error) {
    const formikErrors = {};
    error.inner.forEach((validationError) => {
      console.log("error is ", validationError);
      const field = validationError.path;
      const message = validationError.message;
      formikErrors[field] = message;
    });
    formik.setErrors({
      [`${activeTab.toLowerCase()}`]: formikErrors,
    });

    Object.keys(values).forEach((field) => {
      formik.setFieldTouched(`${activeTab.toLowerCase()}.${field}`, true);
    });

    return false;
  }
};

export const bottlenecks_text_dependent_values = ["OTHER_SPECIFY"];

export const isBottleneckTextAreaEnabled = (value) => {
  let check = false;
  if (Array.isArray(value)) {
    value.forEach((item) => {
      if (
        bottlenecks_text_dependent_values.includes(item?.value?.toUpperCase())
      ) {
        check = true;
        return;
      }
    });
  }
  return check;
};
export const getDateTimeFormat = (date) => {
  const parsedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
  return parsedDate.format(DATE_TIME_FORMAT);
};
export const getCurrentWeekFromDate = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm").isoWeek();
};
export const getUpdatedPayload = (values) => {
  const data = { ...values };
  if (data.emergence.disease_id?.value)
    data.emergence.disease_id = data.emergence.disease_id.value;
  else {
    data.emergence.disease_id = "";
  }
  // if (data.emergence.event_type?.value)
  //   data.emergence.event_type = data.emergence.event_type.value;
  if (data.emergence.health_directorate?.value)
    data.emergence.health_directorate = data.emergence.health_directorate.value;
  else {
    data.emergence.health_directorate = "";
  }
  if (data.emergence.health_facilities?.value)
    data.emergence.health_facilities = data.emergence.health_facilities.value;
  if (data.emergence.responsible_department?.value)
    data.emergence.responsible_department =
      data.emergence.responsible_department.value;
  else {
    data.emergence.responsible_department = "";
  }
  if (data.emergence.association_with_certain_facility?.value)
    data.emergence.association_with_certain_facility =
      data.emergence.association_with_certain_facility.value;

  if (data.emergence?.event_type?.length > 0) {
    data.emergence.event_type = data.emergence.event_type.map(
      (item) => item.value
    );
  }
  if (data.detection.detection_bottlenecks?.filter((item) => item?.value))
    data.detection.detection_bottlenecks =
      data.detection.detection_bottlenecks?.map((item) => item.value);
  if (data.detection?.multi_sites === "not-applicable") {
    data.detection.multi_sites_id = [];
  }
  // if (data.emergence.association_with_public_events?.value)
  //   data.emergence.association_with_public_events =
  //     data.emergence.association_with_public_events.value;
  if (data.notification.notification_bottlenecks?.filter((item) => item?.value))
    data.notification.notification_bottlenecks =
      data.notification.notification_bottlenecks?.map((item) => item.value);

  if (data.response.response_bottlenecks?.filter((item) => item?.value))
    data.response.response_bottlenecks =
      data.response.response_bottlenecks?.map((item) => item?.value);
  data.closure.event_status = data.closure.event_status?.value;

  console.log("data is ", data);
  return data;
};

export const reMapingData = (data, allDropdowns, responsibleDepartments) => {
  if (allDropdowns)
    return {
      emergence: {
        ...data.emergence,
        date_of_creation:
          data.formatteddates?.creation_date &&
          data.formatteddates?.creation_date !== null
            ? moment(data.formatteddates?.creation_date).format(
                DATE_TIME_FORMAT
              )
            : "",
        date_of_emergence:
          data.formatteddates?.date_of_emergence &&
          data.formatteddates?.date_of_emergence !== null
            ? moment(data.formatteddates?.date_of_emergence).format(
                DATE_TIME_FORMAT
              )
            : "",
        disease_id: _.find(allDropdowns.DISEASE, {
          _id: data?.emergence?.disease_id,
        })
          ? _.mapKeys(
              _.find(allDropdowns.DISEASE, { _id: data.emergence.disease_id }),
              (value, key) =>
                key === "_id"
                  ? "value"
                  : key === "disease_name_en"
                  ? "label"
                  : key
            )
          : "",
        responsible_department: _.find(responsibleDepartments, {
          _id: data.emergence.responsible_department,
        })
          ? _.mapKeys(
              _.find(responsibleDepartments, {
                _id: data.emergence.responsible_department,
              }),
              (value, key) =>
                key === "_id" ? "value" : key === "name" ? "label" : key
            )
          : "",
        // event_type: _.find(allDropdowns.EVENT_TYPE, {
        //   value: data.emergence.event_type,
        // }),
        health_directorate: _.find(allDropdowns.HEALTH_DIRECTORATE, {
          _id: data.emergence.health_directorate,
        })
          ? _.mapKeys(
              _.find(allDropdowns.HEALTH_DIRECTORATE, {
                _id: data.emergence.health_directorate,
              }),
              (value, key) =>
                key === "_id"
                  ? "value"
                  : key === "region_name_en"
                  ? "label"
                  : key
            )
          : "",
        health_facilities: data?.health_facilities
          ? {
              label: data.health_facilities?.site_name_en,
              value: data.health_facilities._id,
            }
          : "",
        // association_with_certain_facility: _.find(
        //   certain_facilities_dropdonw[data?.emergence?.event_type],
        //   { value: data?.emergence?.association_with_certain_facility }
        // ),
        epi_week: data?.formatteddates?.date_of_emergence
          ? getCurrentWeekFromDate(data.formatteddates.date_of_emergence)
          : "",
        // association_with_public_events: _.find(
        //   allDropdowns?.ASSOCIATION_WITH_PUBLIC_EVENTS,
        //   { value: data?.emergence?.association_with_public_events }
        // ),
        event_type:
          data?.event_type?.map((item) => ({
            label: item?.name || "",
            value: item._id,
          })) || [],
      },
      detection: {
        ...data.detection,
        date_of_detection:
          data.formatteddates?.date_of_detection &&
          data.formatteddates?.date_of_detection !== null
            ? moment(data.formatteddates?.date_of_detection).format(
                DATE_TIME_FORMAT
              )
            : "",
        detection_bottlenecks: _.chain(allDropdowns?.DETECTION_BOTTLENECKS)
          .flatMap((item) =>
            _.map(
              _.filter(item.options, (option) =>
                data.detection.detection_bottlenecks.includes(option.value)
              ),
              (option) => ({ label: item.label, value: option.value })
            )
          )
          .value(),

        detection_bottlenecks: _.chain(allDropdowns?.DETECTION_BOTTLENECKS)
          .flatMap((item) =>
            _.map(
              _.filter(item.options, (option) =>
                data.detection.detection_bottlenecks.includes(option.value)
              ),
              (option) => ({ label: item.label, value: option.value })
            )
          )
          .value(),
        multi_sites:
          data?.multi_site?.length > 0 ? "applicable" : "not-applicable",
        multi_site_listing: data?.multi_site ? data.multi_site : [],
      },
      notification: {
        ...data?.notification,
        date_of_notification:
          data.formatteddates?.date_of_notification &&
          data.formatteddates?.date_of_notification !== null
            ? moment(data.formatteddates?.date_of_notification).format(
                DATE_TIME_FORMAT
              )
            : "",
        notification_bottlenecks: _.chain(allDropdowns.NOTIFICATION_BOTTLENECKS)
          .flatMap((item) =>
            _.map(
              _.filter(item.options, (option) =>
                data.notification?.notification_bottlenecks.includes(
                  option.value
                )
              ),
              (option) => ({ label: item.label, value: option.value })
            )
          )
          .value(),

        // notification_source: _.mapKeys(
        //   _.find(notification_source_options, {
        //     _id: data.notification.notification_source,
        //   }),
        //   (value, key) =>
        //     key === "_id" ? "value" : key === "disease_name_en" ? "label" : key
        // ),
        // event_existance: _.mapKeys(
        //   _.find(event_existance_options, {
        //     _id: data.notification.event_existance,
        //   }),
        //   (value, key) =>
        //     key === "_id" ? "value" : key === "disease_name_en" ? "label" : key
        // ),
      },
      response: {
        ...data.response,
        // date_of_response_initiation: moment(
        //   data.formatteddates?.date_of_response_initiation
        // ).format(DATE_TIME_FORMAT),
        // date_of_response_completion: moment(
        //   data.formatteddates?.date_of_response_completion
        // ).format(DATE_TIME_FORMAT),
        response_bottlenecks: _.chain(allDropdowns.RESPONSE_BOTTLENECKS)
          .flatMap((item) =>
            _.map(
              _.filter(item.options, (option) =>
                data.response.response_bottlenecks.includes(option.value)
              ),
              (option) => ({ label: item.label, value: option.value })
            )
          )
          .value(),
        response_action: data?.response?.response_action.map((item) => ({
          ...item,
          date_of_initiation:
            item.date_of_initiation && item.date_of_initiation !== null
              ? moment(item.date_of_initiation).format(DATE_TIME_FORMAT)
              : "",
          date_of_completion:
            item.date_of_completion && item.date_of_completion !== null
              ? moment(item.date_of_completion).format(DATE_TIME_FORMAT)
              : "",
        })),
      },
      lineList: {
        ...data.lineList,
      },

      closure: {
        ...data.closure,
        date_of_outbreak_end:
          data.formatteddates?.date_of_outbreak_end &&
          data.formatteddates?.date_of_outbreak_end !== null
            ? moment(data.formatteddates?.date_of_outbreak_end).format(
                DATE_TIME_FORMAT
              )
            : "",
        event_status: _.find(allDropdowns.EVENT_STATUS, {
          value: data.closure.event_status,
        }),
      },
    };
};
export const text_area_dependent_values = [
  "RESTAURANT",
  "HOME",
  "NON_HEALTH_FACILITY",
  "HEALTH_FACILITY",
];
export const certain_facility_depends_on = ["HEALTH_FACILITY"];
export const isShowCertainFacility = (value) => {
  if (typeof value === "string") {
    return certain_facility_depends_on.includes(value?.toUpperCase());
  }
  return false;
};
export const isTextAreaEnabled = (value) => {
  if (typeof value === "string") {
    return text_area_dependent_values.includes(value?.toUpperCase());
  }
  return false;
};
const MAKKAH = "Makkah";
const MADINA = "Madinah";
export const isMakkahHealthDirectorate = (value) => {
  const givenValue = value?.toLowerCase();
  return givenValue?.includes(MAKKAH.toLowerCase());
};
export const isMadinaHealthDirectorate = (value) => {
  const givenValue = value?.toLowerCase();

  return (
    givenValue?.includes(MADINA.toLowerCase()) ||
    givenValue?.includes("medinah")
  );
};

export const isAssociatedFacilityDropdownEnable = (value) => {
  return (
    value?.length &&
    value?.some?.((option) => option.value === "HEALTH_FACILITY")
  );
};
export const isAssociatedTextDropdownEnable = (value) => {
  return (
    value?.length &&
    value?.some?.((option) => option.value === "NON_HEALTH_FACILITY")
  );
};

export const certain_facilities_dropdonw = [
  // {
  //   label: "Restaurant (Public FBDO) ",
  //   value: "RESTAURANT",
  // },
  // {
  //   label: "Home (FBDO)",
  //   value: "HOME",
  // },
  // {
  //   label: "No Observed association (community)",
  //   value: "NO_OBSERVED_ASSOCIATION",
  // },

  {
    label: "Health facilities",
    value: "HEALTH_FACILITY",
  },
  {
    label: "Non-health facility",
    value: "NON_HEALTH_FACILITY",
  },
  // {
  //   label: "No Observed association (community)",
  //   value: "NO_OBSERVED_ASSOCIATION",
  // },

  // {
  //   label: "No Observed association (community)",
  //   value: "NO_OBSERVED_ASSOCIATION",
  // },

  // {
  //   label: "No Observed association (community)",
  //   value: "NO_OBSERVED_ASSOCIATION",
  // },

  // {
  //   label: "No Observed association (community)",
  //   value: "NO_OBSERVED_ASSOCIATION",
  // },
];

export const non_health_facility = {
  "Foodborne disease outbreak": [
    {
      label: "Home source",
      value: "Home source",
    },
    {
      label: "Public source - Restaurant",
      value: "Public source - Restaurant",
    },
    {
      label: "Public source - School",
      value: "Public source - School",
    },
    {
      label: "Public source - Catering kitchens",
      value: "Public source - Catering kitchens",
    },
    {
      label: "Public source - Social Care Home",
      value: "Public source - Social Care Home",
    },
    {
      label: "Public source - Prison",
      value: "Public source - Prison",
    },
    {
      label: "Public source - Street hawker",
      value: "Public source - Street hawker",
    },
    {
      label: "Public source - Others",
      value: "Public source - Others",
    },
  ],
};

export const Chemical_or_drug_event = [
  {
    label: "Restaurant, Catering Kitchen",
    value: "Restaurant, Catering Kitchen"
  },
  {
    label: "School, Kindergarten",
    value: "School, Kindergarten"
  },
  {
    label: "Social Care Home",
    value: "Social Care Home"
  },
  {
    label: "Prison",
    value: "Prison"
  },
  {
    label: "Residential Facility",
    value: "Residential Facility"
  },
  {
    label: "Other",
    value: "Other"
  }
];
export const Endemic_disease_outbreak = [];
export const Environmental_event = [];
export const Foodborne_disease_outbreak = [];
export const Non_endemic_disease_outbreak = [];
export const Other_Unknown = [];
export const Radiological_or_nuclear_emergency = [];

export const notification_source_options = [
  {
    label: "HEWS",
    value: "HEWS",
  },
  {
    label: "HESN+ automated alert",
    value: "HESN_AUTOMATED_ALERT",
  },
  {
    label: "Social listening",
    value: "SOCIAL_LISTING",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];
export const event_existance_options = [
  {
    label: "Suspected/Under investigation",
    value: "UNDER_INVESTIGATION",
  },
  {
    label: "Verified",
    value: "CONFIRMED",
  },
];
// const type = getUserData().type?.toLowerCase();
// const selectedRole = getUserData()?.roles?.find(
//   (item) => getUserData().type === item.name.toLowerCase()
// );

const type = () => {
  return getUserData().type?.toLowerCase()
}

const selectedRole = () => {
  return getUserData()?.roles?.find((item) => getUserData().type === item.name.toLowerCase())
}
export const canCreateEmergence = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "emergence") || type === "admin"
}

export const canUpdateEmergence = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "emergence") || type === "admin"
}

export const canCreateDetection = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "detection") || type === "admin"
}

export const canUpdateDetection = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "detection") || type === "admin"
}

export const canCreateNotification = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "notification") || type === "admin"
}

export const canUpdateNotification = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "notification") || type === "admin"
}

export const canCreateResponse = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "response") || type === "admin"
}

export const canUpdateResponse = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "response") || type === "admin"
}

export const canCreateLineList = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "linelist") || type === "admin"
}

export const canUpdateLineList = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "linelist") || type === "admin"
}

export const canCreateClosure = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "closure") || type === "admin"
}

export const canUpdateClosure = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  return !!selectedRole?.permissions?.find((item) => item?.name === "closure") || type === "admin"
}

export const canOpenClosureDropdown = () => {
  const type = getType()
  const selectedRole = getSelectedRole()
  !!selectedRole?.permissions?.find((item) => item?.name === "can-complete") ||
  !!selectedRole?.permissions?.find((item) => item?.name === "can-close") ||
  !!selectedRole?.permissions?.find((item) => item?.name === "can-reopen") ||
  type === "admin";
}

export const checkFormStatus = (formStatus) => {
  if (formStatus === 'CLOSED' ) return true
    else return false;
}

export const canCompleteClose = (value) => {
  if (value.toLowerCase() === "completed") {
    console.log(
      "permissions",
      !!selectedRole?.permissions?.find(
        (item) => item?.name === "can-complete"
      ) || type === "admin"
    );
    return (
      !!selectedRole?.permissions?.find(
        (item) => item?.name === "can-complete"
      ) || type === "admin"
    );
  } else if (value.toLowerCase() === "closed") {
    return (
      !!selectedRole?.permissions?.find((item) => item?.name === "can-close") ||
      type === "admin"
    );
  } else if (value.toLowerCase() === "reopened") {
    return (
      !!selectedRole?.permissions?.find(
        (item) => item?.name === "can-reopen"
      ) || type === "admin"
    );
  }
  return true;
};
export const canReopen = () =>
  !!selectedRole?.permissions?.find((item) => item?.name === "can-reopen") ||
  type === "admin";
