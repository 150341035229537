import { useState } from "react";
import { authHeader } from "../Helper/authHeader";
// import { baseUrl } from "../Helper/ApiCall";
import axios from "axios";
// import { handleLogin } from "@store/actions/auth";
// import { _id } from "./RefreshToken";
// import { toast } from "react-toastify";
// import { versionControl } from "./VersionControl";
const baseUrl = process.env.REACT_APP_BASEURL;

export const SC = {
  getCall,
  postCall,
  putCall,
  deleteCall,
  postCallWithOrg,
  postCallLoginAdmin,
  postCallWithoutAuth,
  getCallWithUserId,
  getCallWithOrgId,
  getCallWithParams,
  putCallWithOrg,
  postAttachment,
  getCallWithId,
  getCallWithoutAuth,
  deleteCallWithPost,
  getCallWithTeam,
};
function getCall(url, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return axios
    .get(baseUrl + url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function getCallWithOrgId(url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let id = localStorage.getItem("orgId");
  return axios
    .get(
      baseUrl +
        url +
        `organization_id=${id}&version=${process.env.REACT_APP_VERSION}`,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function getCallWithParams({ url, params }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    params,
  };
  let id = localStorage.getItem("orgId");
  return axios
    .get(
      baseUrl +
        url +
        "?version=" +
        process.env.REACT_APP_VERSION +
        `organization_id=${id}`,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function postCall(url, data) {
  // Date.prototype.toJSON = function () {
  //   // return moment(this).format();
  // };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
    // onUploadProgress: function (progressEvent) {
    //   // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
    //   if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    // },
  };
  // if (source) {
  //   requestOptions.cancelToken = source.token;
  // }
  return axios
    .post(baseUrl + url, data, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function postCallWithOrg(url, data, callbackProgressUpload = null, source) {
  Date.prototype.toJSON = function () {
    // return moment(this).format();
  };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };
  if (source) {
    requestOptions.cancelToken = source.token;
  }
  return axios
    .post(
      baseUrl + url + "&version=" + process.env.REACT_APP_VERSION,
      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function postAttachment(url, data, callbackProgressUpload = null, source) {
  Date.prototype.toJSON = function () {
    // return moment(this).format();
  };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };
  if (source) {
    requestOptions.cancelToken = source.token;
  }
  return axios
    .post(url, data, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}
function putCallWithOrg(url, data) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return axios
    .put(
      baseUrl + url + "&version=" + process.env.REACT_APP_VERSION,
      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function putCall(url, data) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return axios
    .put(
      baseUrl + url + "?version=" + process.env.REACT_APP_VERSION,
      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function deleteCall(url) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return axios
    .delete(baseUrl + url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function deleteCallWithPost(url, ids, callbackProgressUpload = null, source) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(ids),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };
  if (source) {
    requestOptions.cancelToken = source.token;
  }
  return axios
    .post(
      baseUrl + url + "?version=" + process.env.REACT_APP_VERSION,
      ids,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}
function postCallLoginAdmin(url, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return axios
    .post(baseUrl + url, data, requestOptions)
    .then((response) => {
      if (response.data) {
        // handleLogin(response.data);
      }
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function getCallWithoutAuth(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return axios
    .get(baseUrl + url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
function postCallWithoutAuth(url, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return axios
    .post(
      baseUrl + url + "?version=" + process.env.REACT_APP_VERSION,
      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
//get data with user id
function getCallWithUserId(url) {
  let user = JSON.parse(localStorage.getItem("Uesr_Data"));
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return axios
    .get(
      baseUrl +
        url +
        "/" +
        user._id +
        "?version=" +
        process.env.REACT_APP_VERSION,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
//get data with  id
function getCallWithId(url, id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return axios
    .get(
      baseUrl + url + "/" + id + "?version=" + process.env.REACT_APP_VERSION,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
//get data with  team id
function getCallWithTeam(url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return axios
    .get(
      baseUrl + url + "&version=" + process.env.REACT_APP_VERSION,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}
