import * as yup from "yup";
import {
  isBottleneckTextAreaEnabled,
  isTextAreaEnabled,
} from "../../views/pages/Add/OutbreakInformation/helpers";

export const detectionValidationSchema = yup.object().shape({
  date_of_detection: yup.mixed().required("Required *").nullable(),
  detection_narrative: yup.string().nullable().nullable(),
  detection_bottlenecks: yup
    .array()
    .test(
      "isRequired",
      "Please select min one and max three elements",
      function (arr) {
        if (!arr || arr.length === 0) {
          // No elements selected
          return false;
        }

        // Check if other specifiy is selected
        const isOtherSpecifySelected = arr.some(
          (item) => item.value === "OTHER_SPECIFY"
        );

        if (isOtherSpecifySelected) {
          // other specifiy is selected, check the length
          if (arr.length > 1 && arr.length <= 3) {
            return true; // Valid selection
          } else if (arr.length === 1) {
            return this.createError({
              path: "detection.detection_bottlenecks",
              message:
                "When selecting other specifiy, please ensure another option along with other specifiy.",
            });
          }
          return false;
        }

        // other specifiy is not selected, check the length
        return arr.length >= 1 && arr.length <= 3;
      }
    )
    .nullable(),
  detection_bottlenecks_text: yup
    .string()
    .when("detection_bottlenecks", {
      is: (value) => isBottleneckTextAreaEnabled(value),
      then: yup.string().required("Required *").nullable(),
      otherwise: yup.string().nullable(),
    })
    .nullable(),
  detection_enablers: yup.string().nullable(),
  multi_sites: yup.string().nullable(),
  // multi_sites_id: yup
  //   .array()
  //   .when("multi_sites", {
  //     is: (value) => value === "applicable",
  //     then: yup
  //       .array()
  //       .test("isRequired", "Please select Multisite", function (arr) {
  //         return arr?.length >= 1;
  //       })
  //       .nullable(),
  //     otherwise: yup.array().nullable(),
  //   })
  //   .nullable(),
});

export const detectionWithoutValidationSchema = yup.object().shape({
  date_of_detection: yup.mixed().nullable(),
  detection_narrative: yup.string().nullable(),
  detection_bottlenecks: yup.array().nullable(),
  detection_enablers: yup.string().nullable(),
  detection_bottlenecks_text: yup.string().nullable(),
  multi_sites_id: yup.array().nullable(),
  multi_sites: yup.string().nullable(),
});

export const detectionValidationSchemaSchemaForOpenStatus = yup.object().shape({
  date_of_detection: yup.mixed().nullable(),
  detection_narrative: yup.string().nullable(),
  detection_bottlenecks: yup.array().nullable(),
  detection_enablers: yup.string().nullable(),
  detection_bottlenecks_text: yup.string().nullable(),
  multi_sites_id: yup.array().nullable(),
  multi_sites: yup.string().nullable(),
});

export const detectionInitialValues = {
  date_of_detection: "",
  detection_narrative: "",
  detection_bottlenecks: [],
  detection_enablers: "",
  multi_sites: "not-applicable",
  detection_bottlenecks_text: "",
  multi_site_listing: [],
  multi_sites_id: [],
};
export const detection_keys = [
  { title: "Date of detection", key: "date_of_detection" },
  { title: "Detection Narrative", key: "detection_narrative" },
  { title: "Detection Bottlenecks", key: "detection_bottlenecks" },
  { title: "Detection Bottlenecks text", key: "detection_bottlenecks_text" },
  { title: "Detection Enablers", key: "detection_enablers" },
  { title: "Multi-Sites", key: "multi_sites" },
];
