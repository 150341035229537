import React from "react";
import { FormattedMessage } from "react-intl";

import FlatPicker from "react-flatpickr";

import { Label } from "reactstrap";
import { FormikError } from "../../../pages/Add/OutbreakInformation/helpers";

const DatePickerComponent = ({
  label,
  placeholder = "Date",
  handleChange,
  icon,
  isDisable = false,
  ...rest
}) => {
  return (
    <div className="w-full position-relative">
      {label && (
        <Label className="form-label">
          <FormattedMessage id={label} defaultMessage={label} />
        </Label>
      )}
      <div className="date-picker-container">
        {icon && icon}
        <FlatPicker
          className={` form-control ${
            isDisable ? "on-disable cursor-not-allowed" : ""
          }`}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={isDisable}
          {...rest}
        />
      </div>
      {rest?.name && rest?.formik && <>{FormikError(rest.formik, rest.name)}</>}
    </div>
  );
};

export default DatePickerComponent;
