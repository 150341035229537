import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { SC } from "../../Api/serverCall";
import { DownloadIcon } from "../../../../assets/wasfaty/SVG";

import "./style.scss";

const ExportButton = (props) => {
  const [loading, setLoading] = useState(false);

  const exportData = async () => {
    const { url, model, exportName } = props;
    setLoading(true);
    await SC.getCall({
      url: url,
      params: {
        event_id: model.event_id,
        Date_of_notification: {
          start: model.notification_dates?.[0]?.toDateString(),
          end: model.notification_dates?.[1]?.toDateString(),
        },
        health_directorate: model.health_directorate?.value,
        responsible_department: model.responsible_department?.value,
        form_status: model.form_status?.value,
        disease: model.disease?.value,
      },
      responseType: "blob",
    }).then((res) => {
      try {
        const link = document.createElement("a");
        const url = URL.createObjectURL(res.data);
        link.href = url;
        link.download = exportName || "Export";
        link.click();
        setLoading(false);
        URL.revokeObjectURL(url);
      } catch (error) {
        setLoading(false);
      }
    });
  };

  return (
    <button disabled={loading} className="export-label" onClick={exportData}>
      {loading ? <Spinner size="sm" /> : DownloadIcon}
      <span className="align-middle">Export</span>
    </button>
  );
};

export default ExportButton;
