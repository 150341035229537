import React, { useState, useEffect } from "react";
import TextInputCommon from "../../../components/common/input/TextInput";
import myLocation from "../../../../assets/images/pages/mylocation.svg";

function MapView({ ...rest }) {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(
      document.getElementById("map"),
      {
        center: { lat: 0, lng: 0 },
        zoom: 18,
      }
    );
    setMap(mapInstance);
    const markerInstance = new window.google.maps.Marker({
      map: mapInstance,
      visible: false,
    });
    setMarker(markerInstance);

    const updateMapAndLink = () => {
      if (map && rest.formik.values.emergence.location_link) {
        try {
          const url = new URL(rest.formik.values.emergence.location_link);
          const params = new URLSearchParams(url.search);
          const lat = parseFloat(params.get("q").split(",")[0]);
          const lng = parseFloat(params.get("q").split(",")[1]);

          if (!isNaN(lat) && !isNaN(lng)) {
            map.setCenter({ lat, lng });
            marker.setPosition({ lat, lng });
            marker.setVisible(true);
          } else {
            // Handle invalid link
            marker.setVisible(false);
          }
        } catch (error) {
          console.error("Error parsing location link:", error);
          marker.setVisible(false);
        }
      } else {
        // If no location link is provided, fetch current user's location
        getCurrentLocation();
      }
    };

    mapInstance.addListener("click", (event) => {
      markerInstance.setPosition(event.latLng);
      mapInstance.panTo(event.latLng);
      rest.formik.setFieldValue(
        rest.name,
        `https://www.google.com/maps?q=${event.latLng.toUrlValue()}`
      );
    });
    updateMapAndLink();
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocationLink = `https://www.google.com/maps?q=${latitude},${longitude}`;
          rest.formik.setFieldValue(rest.name, currentLocationLink);

          if (map) {
            const latLng = new window.google.maps.LatLng(latitude, longitude);
            map.setCenter(latLng);
            marker.setPosition(latLng);
            marker.setVisible(true);
          }
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (map && rest.formik.values?.emergence?.location_link) {
      try {
        const url = new URL(rest.formik.values.emergence.location_link);
        const params = new URLSearchParams(url.search);
        const lat = parseFloat(params.get("q").split(",")[0]);
        const lng = parseFloat(params.get("q").split(",")[1]);

        if (!isNaN(lat) && !isNaN(lng)) {
          map.setCenter({ lat, lng });
          marker.setPosition({ lat, lng });
          marker.setVisible(true);
        } else {
          marker.setVisible(false);
        }
      } catch (error) {
        console.error("Error parsing location link:", error);
        marker.setVisible(false);
      }
    }
  }, [map, rest.formik.values?.emergence?.location_link]);

  return (
    <div>
      <TextInputCommon
        icon={
          <img
            src={myLocation}
            onClick={(e) => {
              e.preventDefault();
              getCurrentLocation();
            }}
            className="calendar-icon"
          />
        }
        {...rest}
      />
      <div
        id="map"
        style={{ marginTop: "20px", height: "200px", width: "100%" }}
      ></div>
    </div>
  );
}

function MapViewWrapper({ ...rest }) {
  try {
    return <MapView {...rest} />;
  } catch (error) {
    // Handle the error here (e.g., log it or display a user-friendly message)
    console.error("Error in MapViewWrapper:", error);
    return null; // Render nothing or a fallback UI component
  }
}

export default MapViewWrapper;
