import { observer } from "mobx-react";
import React, { useState } from "react";
import { IntlService } from "../../../../wasfaty/services";
import { FilterBarModel } from "./FilterBarModel";

import FlatPicker from "react-flatpickr";
import { Button, Input } from "reactstrap";
import SelectComponent from "../../../../components/common/select/Select";
import { useLocation } from "react-router-dom";


import "./style.scss";
import ExportButton from "../../../../wasfaty/components/ExportButton/ExportButton";

const FilterBar = observer(
    ({
      getAllItems,
      options,
      noExport = false,
      msEvents = false,
      exportUrl = "outbreak/export",
      filename = "Outbreake",
    }) => {
      const [model, setModel] = useState(() => new FilterBarModel(getAllItems));

      const handleDateChange = (dateType, e) => {
        const dateField = dateType === 'start_date' ? 'end_date' : 'start_date';
        const setDateMethod = model[dateField] ? 'setAttribute' : 'setDate';
        model[setDateMethod](dateType, e);
      };
      const location = useLocation(); 
      const currentPath = location.pathname; 
  
      console.log("Current Path:", currentPath); 

      if (!model) return null;
      return (
        <>
          <div className="event-list-filter">
            <div className="event-list-filter-child">
              <FlatPicker
                className="form-control filter-input-fields date-pick"
                options={{
                  dateFormat: "d-m-Y",
                  maxDate: model.end_date?.[0]

                }}
                onChange={(e) => handleDateChange('start_date', e)}
                value={model.start_date}
                placeholder={IntlService.m("Select From Date of Notification")}
              />

              <FlatPicker
                className="form-control filter-input-fields date-pick"
                options={{
                  dateFormat: "d-m-Y",
                  minDate: model.start_date?.[0]
                }}
                onChange={(e) => handleDateChange('end_date', e)}
                value={model.end_date}
                placeholder={IntlService.m("Select To Date of Notification")}
              />

              {/* <Input
                className="filter-input-fields"
                // placeholder={IntlService.m("Search in the list")}
                onChange={(e) => model.setAttribute("event_id", e.target.value)}
                value={model.event_id}
                placeholder={IntlService.m(
                  msEvents ? "Enter MS Event ID" : "Enter Event ID"
                )}
              /> */}


              {currentPath === "/Dashboard" && <SelectComponent
                name="regions"
                value={model.regions}
                onChange={(value) => {
                  model.setAttribute("regions", value);
                }}
                options={options}
                placeholder={IntlService.m("Regions")}
                className="react-select filter-input-fields-multi"
                classNamePrefix="select"
                isClearable={true}
                isMulti
              /> }



              <SelectComponent
                name="event_id"
                value={model.event_id}
                onChange={(value) => {
                  model.setAttribute("event_id", value);
                }}
                options={model?.filterOptions?.event_ids?.map((item) => {
                  return { label: item, value: item };
                })}
                placeholder={IntlService.m("Event ID")}
                className="react-select filter-input-fields-multi"
                classNamePrefix="select"
                isClearable={true}
                isMulti
              />

              <SelectComponent
                name="disease"
                value={model.disease}
                onChange={(value) => {
                  model.setAttribute("disease", value);
                }}
                options={model?.filterOptions?.DISEASE?.map((item) => {
                  return { ...item, label: item.disease_name_en, value: item._id };
                })}
                placeholder={IntlService.m("Event Type")}
                className="react-select filter-input-fields-multi"
                classNamePrefix="select"
                isClearable={true}
                isMulti
              />
              
              <SelectComponent
                name="event"
                value={model.event}
                onChange={(value) => {
                  model.setAttribute("event", value);
                }}
                options={model?.filterOptions?.EVENTS?.map((item) => {
                  return { ...item, label: item.name, value: item._id };
                })}
                placeholder={IntlService.m("Event")}
                className="react-select filter-input-fields-multi"
                classNamePrefix="select"
                isClearable={true}
                isMulti
              />
              {currentPath !== "/Dashboard" &&
              <SelectComponent
                isClearable={true}
                name="health_directorate"
                value={model.health_directorate}
                onChange={(value) => {
                  model.setAttribute("health_directorate", value);
                }}
                options={model?.filterOptions?.HEALTH_DIRECTORATE?.filter(
                  (item) => item?.region_name_en
                ).map((item) => {
                  if (item?.region_name_en)
                    return { ...item, label: item.region_name_en, value: item._id };
                })}
                placeholder={IntlService.m("Health Directorate")}
                className="react-select filter-input-fields-multi"
                classNamePrefix="select"
                isMulti
              />}
              <SelectComponent
                isClearable={true}
                name="responsible_department"
                onChange={(value) => {
                  model.setAttribute("responsible_department", value);
                }}
                value={model.responsible_department}
                options={model?.filterOptions?.RESPONSIBLE_DEPARTMENT?.map(
                  (item) => {
                    return { ...item, label: item?.name, value: item?._id };
                  }
                )}
                placeholder={IntlService.m("Responsible Department")}
                className="react-select filter-input-fields-multi"
                classNamePrefix="select"
                isMulti
              />
              <SelectComponent
                isClearable={true}
                name="form_status"
                value={model.form_status}
                onChange={(value) => {
                  model.setAttribute("form_status", value);
                }}
                options={model?.filterOptions?.EVENT_STATUS}
                placeholder={IntlService.m("Event Status")}
                className="react-select filter-input-fields-multi"
                classNamePrefix="select"
                isMulti
              />
            </div>
            <div className="d-flex justify-content-end gap-2 width-full couple-buttons">
              <Button className="reset" onClick={() => model.reset()}>
                Reset
              </Button>
              {!noExport && currentPath !== "/Dashboard" && (
            <ExportButton url={exportUrl} exportName={filename} model={model} />
          )}
            </div>
          </div>
        </>
      );
    }
);

export default FilterBar;
