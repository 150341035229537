import { makeAutoObservable } from "mobx";
import { SC } from "../../../../wasfaty/Api/serverCall";

import debounce from "lodash/debounce";

export class FilterBarModel {
  filterOptions = null;
  regions = null;
  event_id = null;
  start_date = null;
  end_date = null;
  health_directorate = null;
  responsible_department = null;
  form_status = null;
  disease = null;
  event = null;
  serverCall = null;

  constructor(props) {
    if (props) {
      this.serverCall = props;
    }
    this.getDropDowns();

    makeAutoObservable(this);
  }

  reset = async () => {
    this.regions = null;
    this.event_id = "";
    this.start_date = null;
    this.end_date = null;
    this.health_directorate = null;
    this.responsible_department = null;
    this.form_status = null;
    this.disease = null;
    this.event = null;
    await this.debouncedServerCall();
  };

  debouncedServerCall = debounce(async () => {
    // Perform your server call here
    this.serverCall(
      { selected: 0 },
      {
        event_id:
          this.event_id?.length > 0
            ? this.event_id?.map((item) => item?.value)
            : "",
            regions: this.regions?.map((item) => item.value),
        Date_of_notification: {
          start: this.start_date?.[0]?.toDateString(),
          end: this.end_date?.[0]?.toDateString(),
        },
        disease: this.disease?.map((item) => item.value),
        event: this.event?.map((item) => item.value),
        health_directorate: this.health_directorate?.map((item) => item.value),
        responsible_department: this.responsible_department?.map(
          (item) => item.value
        ),
        form_status: this.form_status?.map((item) => item.value),
      }
    );
  }, 1000);

  setAttribute = (name, value) => {
    if (name) {
      this[name] = value;
      this.debouncedServerCall();
    }
  };

  setDate = (name, value) => {
    if (name) {
      this[name] = value;
    }
  };

  getDropDowns = () => {
    SC.getCall({ url: "outbreak/formOptions" }).then((res) => {
      console.log(res, "res--->");
      
      if (res?.data) this.filterOptions = res?.data;
    });
  };
}
