import * as yup from "yup";
import { isBottleneckTextAreaEnabled } from "../../views/pages/Add/OutbreakInformation/helpers";

export const notificationValidationSchema = yup.object().shape({
  date_of_notification: yup.mixed().required("Required *").nullable(),
  event_existence_established: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
  notification_narrative: yup.string().nullable(),
  notification_bottlenecks: yup
    .array()
    .test(
      "isRequired",
      "Please select min one and max three elements",
      function (arr) {
        if (!arr || arr.length === 0) {
          // No elements selected
          return false;
        }

        // Check if other specifiy is selected
        const isOtherSpecifySelected = arr.some(
          (item) => item.value === "OTHER_SPECIFY"
        );

        if (isOtherSpecifySelected) {
          // other specifiy is selected, check the length
          if (arr.length > 1 && arr.length <= 3) {
            return true; // Valid selection
          } else if (arr.length === 1) {
            return this.createError({
              path: "notification.notification_bottlenecks",
              message:
                "When selecting other specifiy, please ensure another option along with other specifiy.",
            });
          }
          return false;
        }

        // other specifiy is not selected, check the length
        return arr.length >= 1 && arr.length <= 3;
      }
    )
    .nullable(),
  notification_bottlenecks_text: yup
    .string()
    .when("notification_bottlenecks", {
      is: (value) => isBottleneckTextAreaEnabled(value),
      then: yup.string().required("Required *").nullable(),
      otherwise: yup.string().nullable(),
    })
    .nullable(),
  notification_enablers: yup.string().nullable(),
  notification_source: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
  notification_source_text: yup.string().nullable(),
  event_existance: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
});

export const notificationWithoutValidationSchema = yup.object().shape({
  date_of_notification: yup.mixed().nullable(),
  notification_narrative: yup.string().nullable(),
  notification_bottlenecks: yup.array().nullable(),
  notification_enablers: yup.string().nullable(),
  notification_bottlenecks_text: yup.string().nullable(),
  notification_source: yup.mixed().nullable(),
  event_existance: yup.mixed().nullable(),
  notification_source_text: yup.string().nullable(),
  event_existence_established: yup
    .mixed()
    .test("isRequired", "Please select a value", function (value) {
      return value !== null && value !== undefined && value !== "";
    })
    .nullable(),
});

export const notificationValidationSchemaForOpenStatus = yup.object().shape({
  date_of_notification: yup.mixed().nullable(),
  notification_narrative: yup.string().nullable(),
  notification_bottlenecks: yup.array().nullable(),
  notification_enablers: yup.string().nullable(),
  notification_bottlenecks_text: yup.string().nullable(),
  notification_source: yup.mixed().nullable(),
  event_existance: yup.mixed().nullable(),
  notification_source_text: yup.string().nullable(),
  event_existence_established: yup.mixed().nullable(),
});

export const notificationInitialValues = {
  date_of_notification: "",
  notification_narrative: "",
  notification_bottlenecks: [],
  notification_enablers: "",
  notification_bottlenecks_text: "",
  event_existence_established: "",
  notification_source: "",
  notification_source_free_text: "",
  event_existance: "",
};
export const notification_keys = [
  { title: "Date & Time of Notification", key: "date_of_notification" },
  { title: "Notification Narrative", key: "notification_narrative" },
  { title: "Notification Bottlenecks", key: "notification_bottlenecks" },
  {
    title: "notification bottlenecks text",
    key: "notification_bottlenecks_text",
  },
  { title: "Notification Enablers ", key: "notification_enablers" },

  {
    title: "Event Verification after Response?",
    key: "event_existence_established",
  },
  { title: "Notification Source", key: "notification_source" },
  {
    title: "notification source free text",
    key: "notification_source_free_text",
  },
  { title: "Event Verification at Notification?", key: "event_existance" },
];
