import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Row, Col, Card, CardBody, Dropdown, Label } from "reactstrap";
import { selectThemeColors } from "@utils";

import TextInputCommon from "../../../components/common/input/TextInput";
import DatePickerComponent from "../../../components/common/datePicker/DatePicker";
import SelectComponent from "../../../components/common/select/Select";
import MapView from "./MapView";

import { RegionSiteModal } from "../../../wasfaty/Models/RegionSiteModal";
import { DepartmentService, RegionService } from "../../../wasfaty/services";
import { UncontrolledTooltip } from "reactstrap";
import { AlertCircle } from "react-feather";

import "./style.scss";
import { SC } from "../../../ESurvey/Helper/helper";
import {
  FormikError,
  canCreateEmergence,
  canUpdateEmergence,
  certain_facilities_dropdonw,
  checkFormStatus,
  getDateTimeFormat,
  isAssociatedFacilityDropdownEnable,
  isAssociatedTextDropdownEnable,
  isMadinaHealthDirectorate,
  isMakkahHealthDirectorate,
  isShowCertainFacility,
  isTextAreaEnabled,
  non_health_facility,
} from "../OutbreakInformation/helpers";
import "react-tagsinput/react-tagsinput.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
moment.tz.setDefault("Asia/Riyadh");
const _ = require("lodash");

const Identification = ({ item, siteType, formik, editData }) => {
  const [dropDrowns, setDropDrowns] = useState(undefined);
  const [healthFacilitiesDropdown, setHealthFacilitiesDropdown] =
    useState(undefined);
  const [public_health_options, setpublic_health_options] = useState(undefined);
  const [health_cluster_options, sethealth_cluster_options] =
    useState(undefined);
  const [responsibleDepartments, setResponsibleDepartments] = useState([]);
  const [eventTypeDropdown, setEventTypeDropdown] = useState(undefined);
  const intl = useIntl();
  const { id } = useParams();

  const getTranslation = (text) => {
    return intl.formatMessage({
      id: text,
      defaultMessage: text,
    });
  };

  const getHealthFacilities = async (id) => {
    if (id) {
      try {
        const res = await SC.getCall(`sites?region_id=${id}`);
        if (res?.data) {
          console.log("facilites", res);
          const data = res?.data?.map((item) => ({
            lebel: item?.site_name_en,
            value: item?._id,
          }));
          setHealthFacilitiesDropdown(data);
          return data;
        }
      } catch (error) {
        return [];
      }
    }
  };
  const getEventType = async (id) => {
    if (id) {
      try {
        const res = await SC.getCall(
          `eventType/desiseEventType?disease_id=${id}`
        );
        if (res?.data?.data) {
          const data = res?.data?.data?.map((item) => ({
            lebel: item?.name || "",
            value: item?._id,
          }));

          setEventTypeDropdown(data);
          return data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getDropDowns = () => {
    SC.getCall("outbreak/formOptions?step=EMERGENCE").then((res) => {
      if (res?.data) setDropDrowns(res.data);
    });
  };

  const getResponsibleDepartments = () => {
    DepartmentService.find().then((res) => {
      if (res?.data?.data) {
        setResponsibleDepartments(res.data.data.data);
      }
    });
  };

  const get_public_event_options = (health_id) => {
    SC.getCall(`publicEvent/getPublicEvents?id=${health_id}`).then((res) => {
      let op = res.data.data.map((it) => {
        return { ...it, label: it.name, value: it._id };
      });
      setpublic_health_options(op);
    });
  };

  const get_health_cluster_options = (health_id) => {
    SC.getCall(`healthCluster/getHealthCluster?region_id=${health_id}`).then(
      (res) => {
        let op = res.data.data.map((it) => {
          return { ...it, label: it.name, value: it._id };
        });
        sethealth_cluster_options(op);
      }
    );
  };

  const formStatus = formik.values?.closure?.event_status?.value
  const isDisabled = () => {
    return id ? (checkFormStatus(formStatus) || !canUpdateEmergence) : !canCreateEmergence;
  }

  const msg = () => {
    if (!formik.values?.notification?.date_of_notification) {
      return 'Please select the notification date first';
    } else if (!formik.values?.detection?.date_of_detection) {
      return 'Please select the detection date first';
    }
  }

  useEffect(() => {
    getDropDowns();
    getResponsibleDepartments();
  }, []);

  useEffect(async () => {
    if (formik?.values?.emergence?.health_directorate) {
      get_public_event_options(
        formik?.values?.emergence?.health_directorate.value
      );
      get_health_cluster_options(
        formik?.values?.emergence?.health_directorate.value
      );
      getHealthFacilities(formik?.values?.emergence?.health_directorate.value);
    }
  }, [formik?.values?.emergence?.health_directorate]);
  useEffect(async () => {
    if (formik?.values?.emergence?.disease_id) {
      getEventType(formik?.values?.emergence?.disease_id.value);
    }
  }, [formik?.values?.emergence?.disease_id]);
  console.log("formik", formik.values?.emergence?.disease_id);

  return (
    <Row>
      <Card>
        <CardBody>
          <Row>
            <Col className="mb-1" md="6" sm="12">
              <SelectComponent
                label="Event Type*"
                name="emergence.disease_id"
                value={formik.values?.emergence?.disease_id}
                formik={formik}
                onChange={async (obj) => {
                  setEventTypeDropdown(undefined);
                  formik.setFieldValue("emergence.disease_id", obj);
                  formik.setFieldValue("emergence.event_type", "");
                  formik.setFieldValue("emergence.non_health_facility", "");
                  formik.setFieldValue(
                    "emergence.association_with_certain_facility",
                    ""
                  );
                  await getEventType(obj.value);
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("emergence.disease_id", true);
                }}
                options={dropDrowns?.DISEASE?.map((item) => {
                  return {
                    label: item?.disease_name_en || "",
                    value: item._id,
                  };
                })}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="6" sm="12">
              <SelectComponent
                label="Event*"
                name="emergence.event_type"
                value={formik.values?.emergence?.event_type}
                formik={formik}
                onChange={(value) => {
                  formik.setFieldValue("emergence.event_type", value);
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("emergence.event_type", true);
                }}
                options={eventTypeDropdown?.map((item) => {
                  return {
                    label: getTranslation(item?.lebel || ""),
                    value: item.value,
                  };
                })}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={(checkFormStatus(formStatus) || !formik.values?.emergence?.disease_id?.value)}
                isMulti
              />
            </Col>
            <Col className="mb-1" md="6" sm="12">
              <SelectComponent
                label="Responsible Department*"
                name="emergence.responsible_department"
                value={formik.values?.emergence?.responsible_department}
                formik={formik}
                onChange={(value) => {
                  formik.setFieldValue(
                    "emergence.responsible_department",
                    value
                  );
                }}
                onBlur={(e) => {
                  formik.setFieldTouched(
                    "emergence.responsible_department",
                    true
                  );
                }}
                options={dropDrowns?.RESPONSIBLE_DEPARTMENT?.map((item) => {
                  return {
                    label: item?.name || "",
                    value: item._id,
                  };
                })}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={isDisabled()}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
          <Col className="mb-1" md="6" sm="12">
            <UncontrolledTooltip placement="top" target="show" innerClassName="custom-tooltip">
              • <strong>Non-endemic disease outbreak:</strong> Date of onset of symptoms of the index case. <br />
              • <strong>Endemic disease outbreak:</strong> Date on which the threshold is determined exceeded. <br />
              • <strong>Food-borne disease outbreak:</strong> Date of onset of symptoms of the second case. <br />
              • <strong>Other public health events:</strong> Date of emergence based on a reporting guideline or epidemiological judgement.
            </UncontrolledTooltip>

            {/* Label container */}
            <div 
              id="show" 
              className="d-inline-flex align-items-center"
              style={{ paddingInline: '5px', width: 'fit-content' }}
            >
              <AlertCircle size={18} className="cursor-pointer" style={{marginTop: -5}} />
              <label htmlFor="datePicker" className="form-label" style={{marginLeft: 5}}>Date & time of emergence*</label>
            </div>

            {/* DatePicker Component */}
            <DatePickerComponent
              placeholder="Date"
              name="emergence.date_of_emergence"
              value={formik.values?.emergence?.date_of_emergence}
              formik={formik}
              handleChange={([date]) => {
                formik.setFieldValue(
                  "emergence.date_of_emergence",
                  getDateTimeFormat(date)
                );
                formik.setFieldValue(
                  "emergence.epi_week",
                  moment(getDateTimeFormat(date), "YYYY-MM-DD HH:mm")
                    .add(1, "day")
                    .isoWeek()
                );
              }}
              onBlur={formik.handleBlur}
              options={{
                enableTime: true,
                dateFormat: "Y-m-d H:i",
                maxDate: moment(formik.values?.detection?.date_of_detection).format('YYYY-MM-DD HH:mm:ss')
              }}
              isDisable={isDisabled() || !formik.values?.detection?.date_of_detection}
            />

            {/* Error Message */}
            <div style={{ color: 'red', marginTop: 20, marginLeft: 10, fontSize: 20, fontWeight: 'bold' }}>
              {msg()}
            </div>
          </Col>

            <Col className="mb-1" md="6" sm="12">
              <TextInputCommon
                value={formik.values?.emergence?.epi_week}
                name="emergence.epi_week"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Epi-week*"
                placeholder="Epi-week"
                formik={formik}
                disabled
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col className="mb-1" md="6" sm="12">
              <SelectComponent
                label="Health Region*"
                name="emergence.health_directorate"
                value={formik.values?.emergence?.health_directorate}
                formik={formik}
                onChange={async (obj) => {
                  formik.setFieldValue(
                    "emergence.association_with_certain_facility_text",
                    ""
                  );
                  formik.setFieldValue("emergence.health_facilities", "");
                  formik.setFieldValue(
                    "emergence.association_with_public_events",
                    []
                  );
                  formik.setFieldValue("emergence.health_cluster", []);
                  setHealthFacilitiesDropdown(undefined);
                  await getHealthFacilities(obj?.value);
                  formik.setFieldValue("emergence.health_directorate", obj);
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("emergence.health_directorate", true);
                }}
                options={dropDrowns?.HEALTH_DIRECTORATE?.filter((item) => {
                  return item?.region_name_en ? true : false;
                }).map((item) => {
                  return {
                    label: item?.region_name_en || "",
                    value: item._id,
                  };
                })}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="6" sm="12">
              {/* {isMadinaHealthDirectorate(
                formik.values?.emergence?.health_directorate?.label
              ) ||
              isMakkahHealthDirectorate(
                formik.values?.emergence?.health_directorate?.label
              ) ? ( */}
              <SelectComponent
                label="Health Cluster"
                name="emergence.health_cluster"
                value={formik.values?.emergence?.health_cluster}
                formik={formik}
                onChange={(value) => {
                  formik.setFieldValue("emergence.health_cluster", value);
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("emergence.health_cluster", true);
                }}
                options={health_cluster_options}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={(checkFormStatus(formStatus) || !health_cluster_options?.length)}
                isMulti
              />
            </Col>
            <Col className="mb-1" md="6" sm="12">
              <SelectComponent
                label="Association with certain facility"
                name="emergence.association_with_certain_facility"
                value={
                  formik.values?.emergence?.association_with_certain_facility
                }
                formik={formik}
                onChange={(value) => {
                  // setHealthFacilitiesDropdown(undefined);
                  formik.setFieldValue(
                    "emergence.association_with_certain_facility",
                    value
                  );
                }}
                onBlur={(e) => {
                  formik.setFieldTouched(
                    "emergence.association_with_certain_facility",
                    true
                  );
                }}
                options={certain_facilities_dropdonw?.map((item) => {
                  return {
                    label: getTranslation(item?.label || ""),
                    value: item.value,
                  };
                })}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={(checkFormStatus(formStatus) || !formik.values?.emergence?.disease_id?.value)}
                isMulti
              />
            </Col>

            {isAssociatedFacilityDropdownEnable(
              formik.values?.emergence?.association_with_certain_facility
            ) ? (
              <Col className="mb-1" md="6" sm="12">
                <SelectComponent
                  label={`${
                    formik.values?.emergence?.association_with_certain_facility.find(
                      (option) => option.value === "HEALTH_FACILITY"
                    ).label
                  }`}
                  name="emergence.association_with_certain_facility_text"
                  value={
                    formik.values?.emergence
                      ?.association_with_certain_facility_text
                  }
                  formik={formik}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "emergence.association_with_certain_facility_text",
                      value
                    );
                  }}
                  onBlur={(e) => {
                    formik.setFieldTouched(
                      "emergence.association_with_certain_facility_text",
                      true
                    );
                  }}
                  options={healthFacilitiesDropdown?.map((item) => {
                    return {
                      label: getTranslation(item?.lebel || ""),
                      value: item.value,
                    };
                  })}
                  placeholder={intl.formatMessage({
                    id: "Select",
                    defaultMessage: "Select",
                  })}
                  theme={selectThemeColors}
                  className="react-select custom-select"
                  classNamePrefix="select"
                  isDisabled={(checkFormStatus(formStatus) || !!!healthFacilitiesDropdown)}
                  isMulti
                />
              </Col>
            ) : null}

            {isAssociatedTextDropdownEnable(
              formik.values?.emergence?.association_with_certain_facility
            ) ? (
              <Col className="mb-1" md="6" sm="12">
                <SelectComponent
                  label="Type of Non-health facility"
                  name="emergence.non_health_facility"
                  value={formik.values?.emergence?.non_health_facility}
                  formik={formik}
                  onChange={(value) => {
                    // setHealthFacilitiesDropdown(undefined);
                    formik.setFieldValue(
                      "emergence.non_health_facility",
                      value
                    );
                    formik.setFieldValue(
                      "emergence.association_with_certain_facility_free_text",
                      ""
                    );
                  }}
                  onBlur={(e) => {
                    formik.setFieldTouched(
                      "emergence.non_health_facility",
                      true
                    );
                  }}
                  options={
                    non_health_facility[
                      formik.values?.emergence?.disease_id?.label
                    ]
                      ? non_health_facility[
                          formik.values.emergence.disease_id.label
                        ]
                      : [
                        {
                          "label": "Restaurant, Catering Kitchen",
                          "value": "restaurant_catering_kitchen"
                        },
                        {
                          "label": "School, Kindergarten",
                          "value": "school_kindergarten"
                        },
                        {
                          "label": "Social Care Home",
                          "value": "social_care_home"
                        },
                        {
                          "label": "Prison",
                          "value": "prison"
                        },
                        {
                          "label": "Residential Facility",
                          "value": "residential_facility"
                        },
                        {
                          "label": "Other",
                          "value": "other"
                        }
                        ]
                  }
                  placeholder={intl.formatMessage({
                    id: "Select",
                    defaultMessage: "Select",
                  })}
                  theme={selectThemeColors}
                  className="react-select custom-select"
                  classNamePrefix="select"
                  isDisabled={checkFormStatus(formStatus)}
                />
              </Col>
            ) : null}

            {formik.values?.emergence?.non_health_facility?.value ? (
              <Col className="mb-1" md="6" sm="12">
                <TextInputCommon
                  // label={`${
                  //   formik.values?.emergence?.association_with_certain_facility?.find(
                  //     (option) => option.value === "NON_HEALTH_FACILITY"
                  //   )?.label
                  // }*`}
                  label="Non-health facility Name*"
                  placeholder={
                    formik.values?.emergence?.association_with_certain_facility
                      .label
                  }
                  value={
                    formik.values?.emergence
                      ?.association_with_certain_facility_free_text
                  }
                  name="emergence.association_with_certain_facility_free_text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  disabled={isDisabled()}
                />
              </Col>
            ) : null}
            <Col className="mb-1" md="6" sm="12">
              {/* {isMadinaHealthDirectorate(
                formik.values?.emergence?.health_directorate?.label
              ) ||
              isMakkahHealthDirectorate(
                formik.values?.emergence?.health_directorate?.label
              ) ? ( */}
              <SelectComponent
                label="Association with public events"
                name="emergence.association_with_public_events"
                value={formik.values?.emergence?.association_with_public_events}
                formik={formik}
                onChange={(value) => {
                  formik.setFieldValue(
                    "emergence.association_with_public_events",
                    value
                  );
                }}
                onBlur={(e) => {
                  formik.setFieldTouched(
                    "emergence.association_with_public_events",
                    true
                  );
                }}
                options={
                  // isMadinaHealthDirectorate(
                  //   formik.values?.emergence?.health_directorate?.label
                  // )
                  //   ? dropDrowns?.ASSOCIATION_WITH_PUBLIC_EVENTS?.filter(
                  //       (item) => {
                  //         return isMadinaHealthDirectorate(item?.label);
                  //       }
                  //     )?.map((item) => {
                  //       return {
                  //         label: getTranslation(item?.label || ""),
                  //         value: item.value,
                  //       };
                  //     })
                  //   : isMakkahHealthDirectorate(
                  //       formik.values?.emergence?.health_directorate?.label
                  //     )
                  //   ? dropDrowns?.ASSOCIATION_WITH_PUBLIC_EVENTS?.filter(
                  //       (item) => {
                  //         return isMakkahHealthDirectorate(item?.label);
                  //       }
                  //     )?.map((item) => {
                  //       return {
                  //         label: getTranslation(item?.label || ""),
                  //         value: item.value,
                  //       };
                  //     })
                  //   : healthFacilitiesDropdown?.map((item) => {
                  //       return {
                  //         label: getTranslation(item?.lebel || ""),
                  //         value: item.value,
                  //       };
                  //     })
                  public_health_options
                }
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={(checkFormStatus(formStatus) || !public_health_options?.length)}
                isMulti
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col className="mb-1" md="12" sm="12">
              <TextInputCommon
                label="Emergence Narrative"
                value={formik.values?.emergence?.narrative}
                name="emergence.narrative"
                placeholder="Narrative"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="textarea"
                disabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="12" sm="12">
              <MapView
                value={formik.values?.emergence?.location_link}
                name="emergence.location_link"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Google Map"
                placeholder="Google Map"
                formik={formik}
                isDisable={checkFormStatus(formStatus)}
              />
            </Col>
            <Col className="mb-1" md="12" sm="12">
              <TextInputCommon
                label="Address"
                placeholder="Address"
                value={formik.values?.emergence?.address}
                name="emergence.address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                disabled={isDisabled()}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
};

export default Identification;
