import React from "react";
import { Progress } from "reactstrap";
function FileProgressBar({ value, filename, fileSize }) {
  const fileSizeKB = (fileSize / 1024).toFixed(2) + " KB";
  return (
    <div className="m-1">
      <div className="d-flex justify-content-between">
        <span style={{marginLeft: "5px"}}>{filename}</span>
        <span>{value}%</span>
      </div>
      <div style={{margin: "5px"}}>
      <span >{fileSizeKB}</span>
      </div>
      <Progress value={value} color="#B6A884" />
    </div>
  );
}
export default FileProgressBar;
