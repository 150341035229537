import * as yup from "yup";

const total_suspect_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test("is-numeric", "Total Suspect Cases must be a number", function (value) {
    return /^\d+$/.test(value);
  })
  // .test(
  //   "is-greater-than-0",
  //   "Total Suspect Cases must be greater than 0",
  //   function (value) {
  //     const numberValue = Number(value);
  //     return numberValue > 0;
  //   }
  // );
const hcw_suspect_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "HCW Suspect Cases must not be greater than Total Suspect Cases",
    function (value) {
      const totalSuspectCases = this.parent.total_suspect_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  );
const total_probable_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "is-numeric",
    "Total Probable Cases must be a number",
    function (value) {
      return /^\d+$/.test(value);
    }
  )
  // .test(
  //   "is-greater-than-0",
  //   "Total Probable Cases must be greater than 0",
  //   function (value) {
  //     const numberValue = Number(value);
  //     return numberValue > 0;
  //   }
  // );
const hcw_probable_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "HCW Probable Cases must not be greater than Total Probable Cases",
    function (value) {
      const totalProbableCases = this.parent.total_probable_cases;
      if (!totalProbableCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalProbableCases, 10);
    }
  );

const total_confirmed_cases_for_close = yup
  .string()
  .nullable()
  .test(
    "is-numeric",
    "Total Confirmed Cases must be a number",
    function (value) {
      return !value || /^\d+$/.test(value);
    }
  )
  // .test(
  //   "is-greater-than-0",
  //   "Total confirmed Cases must be greater than 0",
  //   function (value) {
  //     const numberValue = Number(value);
  //     return !value || numberValue > 0;
  //   }
  // );
const hcw_confirmed_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "HCW Confirmed Cases must not be greater than Total Confirmed Cases",
    function (value) {
      const totalConfirmedCases = this.parent.total_confirmed_cases;
      if (!totalConfirmedCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
    }
  );

const death_suspect_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Total Suspect deaths must not be greater than Total Suspect Cases",
    function (value) {
      const totalSuspectCases = this.parent.total_suspect_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  );
const death_probable_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Total Probable deaths must not be greater than Total Probable Cases",
    function (value) {
      const totalProbableCases = this.parent.total_probable_cases;
      if (!totalProbableCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalProbableCases, 10);
    }
  );
const death_confirmed_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Total Confirmed deaths must not be greater than Total Confirmed Cases",
    function (value) {
      const totalConfirmedCases = this.parent.total_confirmed_cases;
      if (!totalConfirmedCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
    }
  );

const hwc_death_suspect_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater-than-total",
    "HCW Suspect deaths must not be greater than Total Suspect Cases",
    function (value) {
      const totalSuspectCases = this.parent.total_suspect_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  )
  .test(
    "not-greater-than-total",
    "HCW Suspect deaths must not be greater than HCW Suspect Cases",
    function (value) {
      const totalSuspectCases = this.parent.hcw_suspect_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  )
  .test(
    "not-greater",
    "HCW Suspect deaths must not be greater than Death Suspect Cases",
    function (value) {
      const deathSuspectCases = this.parent.death_suspect_cases;
      if (!deathSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(deathSuspectCases, 10);
    }
  );
const hwc_death_probable_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater-than-total",
    "HCW Probable deaths must not be greater than Total Probable Cases",
    function (value) {
      const totalSuspectCases = this.parent.total_probable_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  )
  .test(
    "not-greater-than-total",
    "HCW Probable deaths must not be greater than HCW Probable Cases",
    function (value) {
      const totalSuspectCases = this.parent.hcw_probable_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  )
  .test(
    "not-greater",
    "HCW Probable deaths must not be greater than Death Probable Cases",
    function (value) {
      const deathProbableCases = this.parent.death_probable_cases;
      if (!deathProbableCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(deathProbableCases, 10);
    }
  );
const hwc_death_confirmed_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater-than-total",
    "HCW Confirmed deaths must not be greater than Total Confirmed Cases",
    function (value) {
      const totalSuspectCases = this.parent.total_confirmed_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  )
  .test(
    "not-greater-than-total",
    "HCW Confirmed deaths must not be greater than HCW Confirmed Cases",
    function (value) {
      const totalSuspectCases = this.parent.hcw_confirmed_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  )
  .test(
    "not-greater",
    "HCW Confirmed deaths must not be greater than Death Confirmed Cases",
    function (value) {
      const deathConfirmedCases = this.parent.death_confirmed_cases;
      if (!deathConfirmedCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(deathConfirmedCases, 10);
    }
  );

const critical_suspect_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Hospitalized Suspect cases must not be greater than Total Suspect cases",
    function (value) {
      const totalSuspectCases = this.parent.total_suspect_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  );
const critical_probable_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Hospitalized Probable cases must not be greater than Total Probable cases",
    function (value) {
      const totalProbableCases = this.parent.total_probable_cases;
      if (!totalProbableCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalProbableCases, 10);
    }
  );
const critical_confirmed_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Hospitalized Confirmed cases must not be greater than Total Confirmed cases",
    function (value) {
      const totalConfirmedCases = this.parent.total_confirmed_cases;
      if (!totalConfirmedCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
    }
  );
const critical_icu_suspect_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Critical ICU Suspect Cases must not be greater than Totlal Hospitilized Suspect Cases",
    function (value) {
      const totalSuspectCases = this.parent.critical_suspect_cases;
      if (!totalSuspectCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
    }
  );
const critical_icu_probable_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Critical ICU Probable Cases must not be greater than Total Hospitilized Probable Cases",
    function (value) {
      const totalProbableCases = this.parent.critical_probable_cases;
      if (!totalProbableCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalProbableCases, 10);
    }
  );
const critical_icu_confirmed_cases = yup
  .string()
  .required("Required *")
  .nullable()
  .test(
    "not-greater",
    "Critical ICU Confirmed Cases must not be greater than Total Hospitilized Confirmed Cases",
    function (value) {
      const totalConfirmedCases = this.parent.critical_confirmed_cases;
      if (!totalConfirmedCases || !value) {
        return true; // Allow if either value is not defined or empty
      }
      return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
    }
  );

const narrative_suspect_cases = yup.string().required("Required *").nullable();

export const lineListValidationSchema = yup.object().shape({
  total_suspect_cases: yup
    .string()
    .nullable()
    .test(
      "is-numeric",
      "Total Suspect Cases must be a number",
      function (value) {
        // Allow empty values (null or undefined), otherwise validate
        return !value || /^\d+$/.test(value);
      }
    ),
    // .test(
    //   "is-greater-than-0",
    //   "Total Suspect Cases must be greater than 0",
    //   function (value) {
    //     // Allow empty values (null or undefined), otherwise validate
    //     const numberValue = Number(value);
    //     return !value || numberValue > 0;
    //   }
    // ),
  total_probable_cases: yup
    .string()
    .nullable()
    .test(
      "is-numeric",
      "Total Probable Cases must be a number",
      function (value) {
        return !value || /^\d+$/.test(value);
      }
    ),
    // .test(
    //   "is-greater-than-0",
    //   "Total Probable Cases must be greater than 0",
    //   function (value) {
    //     const numberValue = Number(value);
    //     return !value || numberValue > 0;
    //   }
    // ),

  total_confirmed_cases: yup
    .string()
    .nullable(),
    // .test(
    //   "is-greater-than-0",
    //   "Total confirmed Cases must be greater than 0",
    //   function (value) {
    //     const numberValue = Number(value);
    //     return !value || numberValue > 0;
    //   }
    // ),
  total_cases_value: yup
    .string()
    // .required("Required *")
    .nullable()
    .test(
      "at-least-one",
      "At least one case type must filled",
      function (_, context) {
        const suspectCases = context.parent.total_suspect_cases;
        const probableCases = context.parent.total_probable_cases;
        const confirmedCases = context.parent.total_confirmed_cases;

        // Check if any field is valid
        const isSuspectValid =
          /^\d+$/.test(suspectCases) && Number(suspectCases) > 0;
        const isProbableValid =
          /^\d+$/.test(probableCases) && Number(probableCases) > 0;
        const isConfirmedValid =
          /^\d+$/.test(confirmedCases) && Number(confirmedCases) > 0;

        // If none are valid, require total_cases_value to pass validation
        if (!isSuspectValid && !isProbableValid && !isConfirmedValid) {
          const totalCases = context.parent.total_cases_value;
          return /^\d+$/.test(totalCases) && Number(totalCases) > 0;
        }

        return true;
      }
    ),
  hcw_suspect_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "HCW Suspect Cases must not be greater than Total Suspect Cases",
      function (value) {
        const totalSuspectCases = this.parent.total_suspect_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    ),
  hcw_probable_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "HCW Probable Cases must not be greater than Total Probable Cases",
      function (value) {
        const totalProbableCases = this.parent.total_probable_cases;
        if (!totalProbableCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalProbableCases, 10);
      }
    ),
  hcw_confirmed_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "HCW Confirmed Cases must not be greater than Total Confirmed Cases",
      function (value) {
        const totalConfirmedCases = this.parent.total_confirmed_cases;
        if (!totalConfirmedCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
      }
    ),
  hcw_confirmed_case: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "HCW Confirmed Cases must not be greater than Total Confirmed Cases",
      function (value) {
        const totalConfirmedCases = this.parent.total_confirmed_cases;
        if (!totalConfirmedCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
      }
    ),

  death_suspect_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Total Suspect deaths must not be greater than Total Suspect Cases",
      function (value) {
        const totalSuspectCases = this.parent.total_suspect_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    ),
  death_probable_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Total Probable deaths must not be greater than Total Probable Cases",
      function (value) {
        const totalProbableCases = this.parent.total_probable_cases;
        if (!totalProbableCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalProbableCases, 10);
      }
    ),
  death_confirmed_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Total Confirmed deaths must not be greater than Total Confirmed Cases",
      function (value) {
        const totalConfirmedCases = this.parent.total_confirmed_cases;
        if (!totalConfirmedCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
      }
    ),

  hwc_death_suspect_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater-than-total",
      "HCW Suspect deaths must not be greater than Total Suspect Cases",
      function (value) {
        const totalSuspectCases = this.parent.total_suspect_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    )
    .test(
      "not-greater-than-total",
      "HCW Suspect deaths must not be greater than HCW Suspect Cases",
      function (value) {
        const totalSuspectCases = this.parent.hcw_suspect_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    )
    .test(
      "not-greater",
      "HCW Suspect deaths must not be greater than Death Suspect Cases",
      function (value) {
        const deathSuspectCases = this.parent.death_suspect_cases;
        if (!deathSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(deathSuspectCases, 10);
      }
    ),

  hwc_death_probable_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater-than-total",
      "HCW Probable deaths must not be greater than Total Probable Cases",
      function (value) {
        const totalSuspectCases = this.parent.total_probable_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    )
    .test(
      "not-greater-than-total",
      "HCW Probable deaths must not be greater than HCW Probable Cases",
      function (value) {
        const totalSuspectCases = this.parent.hcw_probable_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    )
    .test(
      "not-greater",
      "HCW Probable deaths must not be greater than Death Probable Cases",
      function (value) {
        const deathProbableCases = this.parent.death_probable_cases;
        if (!deathProbableCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(deathProbableCases, 10);
      }
    ),
  hwc_death_confirmed_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater-than-total",
      "HCW Confirmed deaths must not be greater than Total Confirmed Cases",
      function (value) {
        const totalSuspectCases = this.parent.total_confirmed_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    )
    .test(
      "not-greater-than-total",
      "HCW Confirmed deaths must not be greater than HCW Confirmed Cases",
      function (value) {
        const totalSuspectCases = this.parent.hcw_confirmed_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    )
    .test(
      "not-greater",
      "HCW Confirmed deaths must not be greater than Death Confirmed Cases",
      function (value) {
        const deathConfirmedCases = this.parent.death_confirmed_cases;
        if (!deathConfirmedCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(deathConfirmedCases, 10);
      }
    ),

  critical_suspect_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Hospitalized Suspect cases must not be greater than Total Suspect cases",
      function (value) {
        const totalSuspectCases = this.parent.total_suspect_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    ),
  critical_probable_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Hospitalized Probable cases must not be greater than Total Probable cases",
      function (value) {
        const totalProbableCases = this.parent.total_probable_cases;
        if (!totalProbableCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalProbableCases, 10);
      }
    ),
  critical_confirmed_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Hospitalized Confirmed cases must not be greater than Total Confirmed cases",
      function (value) {
        const totalConfirmedCases = this.parent.total_confirmed_cases;
        if (!totalConfirmedCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
      }
    ),
  critical_icu_suspect_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Critical ICU Suspect Cases must not be greater than Totlal Hospitilized Suspect Cases",
      function (value) {
        const totalSuspectCases = this.parent.critical_suspect_cases;
        if (!totalSuspectCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalSuspectCases, 10);
      }
    ),
  critical_icu_probable_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Critical ICU Probable Cases must not be greater than Total Hospitilized Probable Cases",
      function (value) {
        const totalProbableCases = this.parent.critical_probable_cases;
        if (!totalProbableCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalProbableCases, 10);
      }
    ),
  critical_icu_confirmed_cases: yup
    .string()
    .nullable()
    .test(
      "not-greater",
      "Critical ICU Confirmed Cases must not be greater than Total Hospitilized Confirmed Cases",
      function (value) {
        const totalConfirmedCases = this.parent.critical_confirmed_cases;
        if (!totalConfirmedCases || !value) {
          return true; // Allow if either value is not defined or empty
        }
        return parseInt(value, 10) <= parseInt(totalConfirmedCases, 10);
      }
    ),

  narrative_suspect_cases: yup.string().nullable(),
});
export const lineListValidationSchemaForCloseStatus = yup.object().shape({
  total_suspect_cases: yup
    .string()
    .nullable()
    .test(
      "is-numeric",
      "Total Suspect Cases must be a number",
      function (value) {
        // Allow empty values (null or undefined), otherwise validate
        return !value || /^\d+$/.test(value);
      }
    ),
    // .test(
    //   "is-greater-than-0",
    //   "Total Suspect Cases must be greater than 0",
    //   function (value) {
    //     // Allow empty values (null or undefined), otherwise validate
    //     const numberValue = Number(value);
    //     return !value || numberValue > 0;
    //   }
    // ),
  total_probable_cases: yup
    .string()
    .nullable()
    .test(
      "is-numeric",
      "Total Probable Cases must be a number",
      function (value) {
        return !value || /^\d+$/.test(value);
      }
    ),
    // .test(
    //   "is-greater-than-0",
    //   "Total Probable Cases must be greater than 0",
    //   function (value) {
    //     const numberValue = Number(value);
    //     return !value || numberValue > 0;
    //   }
    // ),
  total_confirmed_cases: total_confirmed_cases_for_close,

  total_cases_value: yup
    .string()
    // .required("Required *")
    .nullable()
    .test(
      "at-least-one",
      "At least one case type must filled",
      function (_, context) {
        const suspectCases = context.parent.total_suspect_cases;
        const probableCases = context.parent.total_probable_cases;
        const confirmedCases = context.parent.total_confirmed_cases;

        // Check if any field is valid
        const isSuspectValid =
          /^\d+$/.test(suspectCases) && Number(suspectCases) > 0;
        const isProbableValid =
          /^\d+$/.test(probableCases) && Number(probableCases) > 0;
        const isConfirmedValid =
          /^\d+$/.test(confirmedCases) && Number(confirmedCases) > 0;

        // If none are valid, require total_cases_value to pass validation
        if (!isSuspectValid && !isProbableValid && !isConfirmedValid) {
          const totalCases = context.parent.total_cases_value;
          return /^\d+$/.test(totalCases) && Number(totalCases) > 0;
        }

        return true;
      }
    ),
  hcw_suspect_cases,
  hcw_probable_cases,
  hcw_confirmed_cases,
  death_suspect_cases,
  death_probable_cases,
  death_confirmed_cases,
  hwc_death_suspect_cases,
  hwc_death_probable_cases,
  hwc_death_confirmed_cases,
  critical_suspect_cases,
  critical_probable_cases,
  critical_confirmed_cases,
  critical_icu_suspect_cases,
  critical_icu_probable_cases,
  critical_icu_confirmed_cases,
  narrative_suspect_cases,
});

export const lineListValidationSchemaForOpenStatus = yup.object().shape({
  total_suspect_cases: yup.string().nullable(),
  hcw_suspect_cases: yup.string().nullable(),
  total_probable_cases: yup.string().nullable(),
  hcw_probable_cases: yup.string().nullable(),
  total_confirmed_cases: yup.string().nullable(),
  hcw_confirmed_cases: yup.string().nullable(),

  death_suspect_cases: yup.string().nullable(),
  death_probable_cases: yup.string().nullable(),
  death_confirmed_cases: yup.string().nullable(),

  hwc_death_suspect_cases: yup.string().nullable(),
  hwc_death_probable_cases: yup.string().nullable(),
  hwc_death_confirmed_cases: yup.string().nullable(),

  critical_suspect_cases: yup.string().nullable(),
  critical_probable_cases: yup.string().nullable(),
  critical_confirmed_cases: yup.string().nullable(),

  critical_icu_suspect_cases: yup.string().nullable(),
  critical_icu_probable_cases: yup.string().nullable(),
  critical_icu_confirmed_cases: yup.string().nullable(),

  narrative_suspect_cases: yup.string().nullable(),
});

export const lineListInitialValues = {
  total_suspect_cases: "",
  total_probable_cases: "",
  total_confirmed_cases: "",
  total_cases_value: "",

  hcw_suspect_cases: "",
  hcw_probable_cases: "",
  hcw_confirmed_cases: "",
  // hcw_cases: "",

  // non_hcw_suspect_cases: "",
  // non_hcw_probable_cases: "",
  // non_hcw_confirmed_cases: "",
  // non_hcw_cases: "",

  death_suspect_cases: "",
  death_probable_cases: "",
  death_confirmed_cases: "",
  // death_cases: "",

  hwc_death_suspect_cases: "",
  hwc_death_probable_cases: "",
  hwc_death_confirmed_cases: "",
  // hwc_death_cases: "",

  // non_hwc_death_suspect_cases: "",
  // non_hwc_death_probable_cases: "",
  // non_hwc_death_confirmed_cases: "",
  // non_hwc_death_cases: "",

  critical_suspect_cases: "",
  critical_probable_cases: "",
  critical_confirmed_cases: "",

  critical_icu_suspect_cases: "",
  critical_icu_probable_cases: "",
  critical_icu_confirmed_cases: "",
  narrative_suspect_cases: "",
  // critical_cases: "",
};

export const lineList_keys = [
  [
    {
      title: "Total Cases",
    },
    {
      key: "total_suspect_cases",
      title: "Suspect",
    },
    {
      key: "total_probable_cases",
      title: "Probable",
    },
    {
      key: "total_confirmed_cases",
      title: "Confirmed",
    },
    {
      key: "total_cases_value",
      title: "Total",
    },
  ],
  [
    {
      title: "HCW cases",
    },
    {
      key: "hcw_suspect_cases",
      title: "Suspect",
    },
    {
      key: "hcw_probable_cases",
      title: "Probable",
    },
    {
      key: "hcw_confirmed_cases",
      title: "Confirmed",
    },
    {
      key: "total_hcw_cases_value",
      title: "Total",
    },
  ],
  [
    {
      title: "Non HCW cases",
    },
    {
      key: "non_hcw_suspect_cases",
      title: "Suspect",
    },
    {
      key: "non_hcw_probable_cases",
      title: "Probable",
    },
    {
      key: "non_hcw_confirmed_cases",
      title: "Confirmed",
    },
    {
      key: "total_non_hcw_cases_value",
      title: "Total",
    },
  ],
  [
    {
      title: "Total deaths",
    },
    {
      key: "death_suspect_cases",
      title: "Suspect",
    },
    {
      key: "death_probable_cases",
      title: "Probable",
    },
    {
      key: "death_confirmed_cases",
      title: "Confirmed",
    },
    {
      key: "total_death_value",
      title: "Total",
    },
  ],
  [
    {
      title: "HCW deaths",
    },
    {
      key: "hwc_death_suspect_cases",
      title: "Suspect",
    },
    {
      key: "hwc_death_probable_cases",
      title: "Probable",
    },
    {
      key: "hwc_death_confirmed_cases",
      title: "Confirmed",
    },
    {
      key: "total_hwc_death_value",
      title: "Total",
    },
  ],
  [
    {
      title: "Non HCW deaths",
    },
    {
      key: "non_hwc_death_suspect_cases",
      title: "Suspect",
    },
    {
      key: "non_hwc_death_probable_cases",
      title: "Probable",
    },
    {
      key: "non_hwc_death_confirmed_cases",
      title: "Confirmed",
    },
    {
      key: "total_non_hcw_death_cases_value",
      title: "Total",
    },
  ],
  [
    {
      title: "Hospitalized",
    },
    {
      key: "critical_suspect_cases",
      title: "Suspect",
    },
    {
      key: "critical_probable_cases",
      title: "Probable",
    },
    {
      key: "critical_confirmed_cases",
      title: "Confirmed",
    },
    {
      key: "total_cirtical_cases_value",
      title: "Total",
    },
  ],
  [
    {
      title: "Critical (ICU)",
    },
    {
      key: "critical_icu_suspect_cases",
      title: "Suspect",
    },
    {
      key: "critical_icu_probable_cases",
      title: "Probable",
    },
    {
      key: "critical_icu_confirmed_cases",
      title: "Confirmed",
    },
    {
      key: "total_cirtical_icu_cases_value",
      title: "Total",
    },
  ],
  [
    {
      title: "Cases Narrative",
    },
    {
      key: "narrative_suspect_cases",
      title: "Suspect",
    },
  ],
];
