import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";
import { Row, Col, Card, CardBody } from "reactstrap";

import { selectThemeColors } from "@utils";
import { RegionSiteModal } from "../../../wasfaty/Models/RegionSiteModal";
import { RegionService } from "../../../wasfaty/services";

import calenderEnable from "../../../../assets/images/pages/calendar-enable.svg";
import clenderToday from "../../../../assets/images/pages/calendartoday.svg";
import TextInputCommon from "../../../components/common/input/TextInput";
import ColorToggleButton from "../components/threeTogglerButton";
import SelectComponent from "../../../components/common/select/Select";
import DatePickerComponent from "../../../components/common/datePicker/DatePicker";

import "./style.scss";
import { SC } from "../../../ESurvey/Helper/helper";
import {
  canCreateResponse,
  canUpdateResponse,
  checkFormStatus,
  getDateTimeFormat,
  isBottleneckTextAreaEnabled,
} from "../OutbreakInformation/helpers";
import moment from "moment";
import {
  EVENT_BE_ASSESSED,
  RESPONSE_STATUS,
} from "../../../../utility/constants/Response";
import { useParams } from "react-router-dom";

const Response = observer(({ item, siteType, formik }) => {
  const [dropDrowns, setDropDrowns] = useState(undefined);
  const [regions, setRegions] = useState([]);
  const [sitesModal] = useState(() => new RegionSiteModal({ siteType }));
  const intl = useIntl();
  const region_id = item?.region?._id;
  const { id } = useParams();

  const getTranslation = (text) => {
    return intl.formatMessage({
      id: text,
      defaultMessage: text,
    });
  };
  const getDropDowns = () => {
    SC.getCall("outbreak/formOptions?step=RESPONSE").then((res) => {
      if (res?.data) setDropDrowns(res.data);
    });
  };
  useEffect(() => {
    getDropDowns();
    getRegions();
  }, []);

  const getRegions = () => {
    RegionService.getByMethod("Pharmacy").then(setRegions);
  };
  useEffect(() => {
    if (region_id) {
      sitesModal.getSites(region_id);
      if (region_id != item?.site?.region_id) item.setAttribute("site", null);
    }
  }, [region_id]);
  useEffect(() => {
    let earliestInitiationDate = null;
    let latestCompletionDate = null;

    formik.values?.response?.response_action.forEach((item) => {
      if (
        item.date_of_initiation &&
        item.status === RESPONSE_STATUS.APPLICABLE
      ) {
        const initiationDate = moment(item.date_of_initiation);
        if (initiationDate.isValid()) {
          if (
            !earliestInitiationDate ||
            initiationDate.isBefore(earliestInitiationDate)
          ) {
            earliestInitiationDate = initiationDate;
          }
        }
      }

      if (
        item.date_of_completion &&
        item.status === RESPONSE_STATUS.APPLICABLE
      ) {
        const completionDate = moment(item.date_of_completion);
        if (completionDate.isValid()) {
          if (
            !latestCompletionDate ||
            completionDate.isAfter(latestCompletionDate)
          ) {
            latestCompletionDate = completionDate;
          }
        }
      }
    });

    if (earliestInitiationDate) {
      formik.setFieldValue(
        "response.date_of_response_initiation",
        getDateTimeFormat(earliestInitiationDate)
      );
    } else {
      formik.setFieldValue("response.date_of_response_initiation", "");
    }
    if (latestCompletionDate) {
      formik.setFieldValue(
        "response.date_of_response_completion",
        getDateTimeFormat(latestCompletionDate)
      );
    } else {
      formik.setFieldValue("response.date_of_response_completion", "");
    }
  }, [formik.values?.response?.response_action]);
  const customError = (index, lastName) => {
    try {
      if (formik.errors?.response?.response_action[index][lastName])
        return (
          <p
            style={{
              position: "absolute",
              bottom: "-35px",
              left: "13px",
              fontSize: "12px",
              fontWeight: 500,
              color: "red",
            }}
          >
            {formik.errors.response.response_action[index][lastName]}
          </p>
        );
      return null;
    } catch (e) {
      return null;
    }
  };
  const isDisabled = (name) => {
    const formStatus = formik.values?.closure?.event_status?.value
    if (name === "datePicker") {
      return (item.status === RESPONSE_STATUS.NOT_APPLICABLE)
      // return (checkFormStatus(formStatus) || item.status === RESPONSE_STATUS.NOT_APPLICABLE)
    } else {
      return id ? (checkFormStatus(formStatus) || !canUpdateResponse) : !canCreateResponse;
    }
  }

  return (
    <Row>
      <table className="table table-borderless table-striped">
        <thead className="main-head">
          <tr className="">
            <th className="th-res-1"></th>
            <th className="th-res">Early response action</th>
            <th className="th-res">Response Status</th>
            <th className="th-res">Date of Initiation</th>
            <th className="th-res-2">Date of Completion</th>
          </tr>
        </thead>
        <tbody>
          {formik.values?.response?.response_action?.map((item, index) => {
            return (
              <tr>
                <td>{`R${index + 1}`}</td>
                <td>{item.description}</td>
                <td>
                  <ColorToggleButton
                    currValue={item.status}
                    setCurrValue={(value) => {
                      formik.setFieldValue(
                        `response.response_action[${index}].status`,
                        value.toUpperCase()
                      );
                      formik.setFieldValue(
                        `response.response_action[${index}].date_of_initiation`,
                        ""
                      );
                      formik.setFieldValue(
                        `response.response_action[${index}].date_of_completion`,
                        ""
                      );
                    }}
                    isDisable={isDisabled()}
                  />
                </td>
                <td>
                  <div className="date-div position-relative mb-1">
                    <DatePickerComponent
                      name={`response.response_action[${index}].date_of_initiation`}
                      formik={formik}
                      placeholder=""
                      value={item.date_of_initiation}
                      handleChange={([date]) => {
                        formik.setFieldValue(
                          `response.response_action[${index}].date_of_initiation`,
                          getDateTimeFormat(date)
                        );
                      }}
                      onBlur={formik.handleBlur}
                      icon={
                        <img src={clenderToday} className="calendar-icon" />
                      }
                      options={{
                        enableTime: true,
                        time_24hr: true,
                        dateFormat: "Y-m-d H:i",
                        minDate: moment(formik.values?.notification?.date_of_notification).format('YYYY-MM-DD HH:mm:ss')
                      }}
                      isDisable={isDisabled('datePicker') || item.status === 'NOT_APPLICABLE'}
                      style={{ minWidth: "200px" }}
                    />
                    {customError(index, "date_of_initiation")}
                  </div>
                </td>
                <td>
                  <div className="date-div position-relative mb-1">
                    <DatePickerComponent
                      name={`response.response_action[${index}].date_of_completion`}
                      formik={formik}
                      placeholder=""
                      value={item.date_of_completion}
                      handleChange={([date]) => {
                        formik.setFieldValue(
                          `response.response_action[${index}].date_of_completion`,
                          getDateTimeFormat(date)
                        );
                      }}
                      onBlur={formik.handleBlur}
                      icon={
                        <img src={clenderToday} className="calendar-icon" />
                      }
                      options={{
                        enableTime: true,
                        time_24hr: true,
                        dateFormat: "Y-m-d H:i",
                        minDate: moment(item.date_of_initiation).format('YYYY-MM-DD HH:mm:ss')
                      }}
                      isDisable={isDisabled('datePicker') || item.status === 'NOT_APPLICABLE' || !item.date_of_initiation}
                      style={{ minWidth: "200px" }}
                    />
                    {customError(index, "date_of_completion")}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Card>
        <CardBody>
          <Row>
            <Col className="mb-1" md="6" sm="12">
              <DatePickerComponent
                label="Date of Response Initiation*"
                placeholder=""
                name="response.date_of_response_initiation"
                value={formik.values?.response?.date_of_response_initiation}
                formik={formik}
                handleChange={([date]) => {
                  formik.setFieldValue(
                    "response.date_of_response_initiation",
                    getDateTimeFormat(date)
                  );
                }}
                onBlur={formik.handleBlur}
                icon={<img src={calenderEnable} className="calendar-icon" />}
                options={{
                  enableTime: true,
                  time_24hr: true,
                  dateFormat: "Y-m-d H:i",
                }}
                isDisable
              />
            </Col>
            <Col className="mb-1" md="6" sm="12">
              <DatePickerComponent
                label="Date of Response Completion*"
                placeholder=""
                name="response.date_of_response_completion"
                value={formik.values?.response?.date_of_response_completion}
                formik={formik}
                handleChange={([date]) => {
                  formik.setFieldValue(
                    "response.date_of_response_completion",
                    getDateTimeFormat(date)
                  );
                }}
                onBlur={formik.handleBlur}
                icon={<img src={calenderEnable} className="calendar-icon" />}
                options={{
                  enableTime: true,
                  time_24hr: true,
                  dateFormat: "Y-m-d H:i",
                }}
                isDisable
              />
            </Col>
            <Col className="mb-1" md="12" sm="12">
              <SelectComponent
                label="Response Bottlenecks*"
                name="response.response_bottlenecks"
                value={formik.values?.response?.response_bottlenecks}
                formik={formik}
                onChange={(value) => {
                  const hasNoBottlenecksOption =
                    formik.values?.response?.response_bottlenecks?.some(
                      (option) => option.value === "NO_BOTTLENECKS"
                    );
                  const hasNoBottlenecksOptionInValue = value?.some(
                    (option) => option.value === "NO_BOTTLENECKS"
                  );
                  if (
                    value.length > 1 &&
                    value[value.length - 1].value === "NO_BOTTLENECKS"
                  ) {
                    console.log("can not select bottleneck");
                  } else if (
                    (!hasNoBottlenecksOption ||
                      !hasNoBottlenecksOptionInValue) &&
                    value.length <= 3
                  )
                    formik.setFieldValue(
                      "response.response_bottlenecks",
                      value
                    );
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("response.response_bottlenecks", true);
                }}
                options={dropDrowns?.RESPONSE_BOTTLENECKS || []}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isMulti
                isDisabled={isDisabled()}
              />
            </Col>
            {isBottleneckTextAreaEnabled(
              formik.values?.response?.response_bottlenecks
            ) && (
              <Col className="mb-1" md="12" sm="12">
                <TextInputCommon
                  value={formik.values?.response?.response_bottlenecks_text}
                  name="response.response_bottlenecks_text"
                  label="Other Specify*"
                  placeholder="Other Specify"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  disabled={isDisabled()}
                />
              </Col>
            )}

            <Col className="mb-1" md="12" sm="12">
              <TextInputCommon
                value={formik.values?.response?.response_enablers}
                name="response.response_enablers"
                label="Response Enablers"
                placeholder="Response Enablers"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                disabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="12" sm="12">
              <TextInputCommon
                value={formik.values?.response?.response_narrative}
                name="response.response_narrative"
                label="Response Narrative"
                placeholder="Response Narrative"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                type="textarea"
                disabled={isDisabled()}
              />
            </Col>
            <Col className="mb-1" md="12" sm="12">
              <SelectComponent
                label="Should the Event be assessed by IHR Focal Person?*"
                name="response.event_be_assessed"
                value={formik.values?.response?.event_be_assessed}
                formik={formik}
                onChange={(obj) => {
                  formik.setFieldValue("response.event_be_assessed", obj);
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("response.event_be_assessed", true);
                }}
                options={EVENT_BE_ASSESSED}
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                theme={selectThemeColors}
                className="react-select custom-select"
                classNamePrefix="select"
                isDisabled={isDisabled()}
              />{" "}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
});

export default Response;
