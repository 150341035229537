import React, { useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Label, Input, Tooltip } from "reactstrap";
import { FormikError } from "../../../pages/Add/OutbreakInformation/helpers";
import { v4 as uuidv4 } from "uuid";

const TextInput = ({
  type = "text",
  label,
  placeholder = "Text",
  icon,
  isDisable = false,
  index,
  onChange,
  min,
  ...rest
}) => {
  const intl = useIntl();
  const inputRef = useRef(null);
  const [uid] = useState(() => uuidv4())
  const tooltipId = `TooltipExample-${uid}`;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleWheel = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || Number(newValue) >= 0) {
      onChange(e);
    }
  };

  return (
    <div className="w-full position-relative mb-1">
      {label && (
        <Label className="form-label">
          <FormattedMessage id={label} defaultMessage={label} />
        </Label>
      )}
      <div className="date-picker-container input-wrapper">
        {icon && icon}
        <Input
          invalid={FormikError(rest.formik, rest.name, true) ? true : false}
          style={{ border: "1px solid red", borderWidth: 5 }}
          className={`from-control-error ${isDisable ? "on-disable" : ""}`}
          disabled={isDisable}
          type={type}
          placeholder={
            placeholder
              ? intl.formatMessage({
                  id: placeholder,
                  defaultMessage: placeholder,
                })
              : ""
          }
          ref={inputRef}
          {...rest}
          onWheel={handleWheel}
          onChange={min === '0' ? handleChange : onChange}
        />
        {FormikError(rest.formik, rest.name, true) && (
          <>
            <div
              id={tooltipId}
              className="exclamation"
            ></div>
            <Tooltip
              style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}
              autohide={true}
              placement="bottom"
              flip={true}
              isOpen={tooltipOpen}
              target={tooltipId}
              toggle={toggle}
            >
              {FormikError(rest.formik, rest.name, true)}
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default TextInput;
